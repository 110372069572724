import React from "react";
// Customizable Area Start
import {
    Grid, Box, Typography,
    Breadcrumbs,
    Button,
  TextField, Divider, Chip, Select, OutlinedInput, MenuItem,
  Paper,ListItem,
  Modal, IconButton,
  InputAdornment,
  TextareaAutosize, 
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControl,Tabs,Tab
} from "@material-ui/core";
import ArrowIcon from "@material-ui/icons/ArrowDropDown";
import Snackbar from '@material-ui/core/Snackbar';
import {
upload_image,sendIcon,checkBox,download,sucess
} from "./assets";
import Header from "../../landingpage/src/Header.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { styled } from "@material-ui/styles";
import { configJSON } from "./CategoriessubcategoriesController";
import ViewReportDetailsController, {
    Props,TabPanelProps
} from "./ViewReportDetailsController.web";
import MuiAlert from "@material-ui/lab/Alert";
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

export class TabPanelTest extends React.Component<TabPanelProps> {
  render() {
    const { children, value, index, ...other } = this.props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}
// Customizable Area End

export default class ViewDiscrepancy extends ViewReportDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    viewReportDetailsBreadCrumb = () => {
        return (<>
            <Box >
                <Breadcrumbs separator={">"} aria-label="breadcrumb">
                    <UploadReportContainer color="inherit" data-testId="breadcrumbId" onClick={this.handleProjNavigation} style={webStyle.reportUnderStyle}>{configJSON.viewBred}</UploadReportContainer>
                    <UploadReportContainer color="inherit" onClick={this.getNavigationViewreport} style={webStyle.reportUnderStyle}>{configJSON.reportOf}'{this.state.project_name}'</UploadReportContainer>
                    <UploadReportContainer color="inherit" style={webStyle.breadcrumb} onClick={this.goBack}>{this.state.reportName}</UploadReportContainer>
                    <Typography style={webStyle.breadcrumbStyle}>{configJSON.discrepancy}</Typography>
                </Breadcrumbs>
            </Box>
        </>
        )
    }
    tableList = () => {
        return (
            <Box className="invoiceData">
                <Typography className="heading">{configJSON.orderQty}</Typography>
                <Typography className="heading">{configJSON.unit}</Typography>
                <Typography className="heading">{configJSON.unitPrice}</Typography>
                <Typography className="heading">{configJSON.totalContent}</Typography>
            </Box>
        )
    }
    invoiceTable = () => {
        return (
            <>
                {this.tableList()}
                {this.state.viewListData && this.state.viewListData.map((item)=> {
                    return (
                        <>
                            <Box className="quoteDetails">
                                <Typography className='invoiceItems'>{item.quote.ord_quantity}</Typography>
                                <Typography className='invoiceItems'>{item.quote.unit}</Typography>
                                <Typography className='invoiceItems'>${item.quote.unit_price}</Typography>
                                <Typography className={item.type === "Overcharged" ? "invoiceItems overChangedItem" : "invoiceItems underChangedItem"}>${item.quote.ext_price}</Typography>
                            </Box>
                        </>
                    )
                })}

            </>
        )
    }
    quoteTable = () => {
        return (
            <>
               {this.tableList()}
                {this.state.viewListData && this.state.viewListData.map((item)=> {
                    return (
                        <>
                            <Box className="invoiceDetails">
                                <Typography className='invoiceItems'>{item.invoice.ord_quantity}</Typography>
                                <Typography className='invoiceItems'>{item.invoice.unit}</Typography>
                                <Typography className='invoiceItems'>${item.invoice.unit_price}</Typography>
                                <Typography className={item.type === "Overcharged" ? "invoiceItems overChangedItem" : "invoiceItems underChangedItem"}>${item.invoice.ext_price}</Typography>
                            </Box>
                        </>
                    )
                })}

            </>
        )
    }
    renderEmailConfirm = () => {
      return (
        <>
          <ConfirmModal open={this.state.emailConfirm}>
            <Paper className="emailContainer">
              <Box>
                <Typography className="emailHeader">
                  {configJSON.confirmEmailHead}
                </Typography>
              </Box>
              <Box className="confirmBox">
                <Typography className="confirmDetails">Vendor: {this.state.vendorName}</Typography>
                <Typography className="confirmDetails">Email: {this.state.emailName}</Typography>
              </Box>
              <Box className="buttonBox">
                <Button className="backBtn" 
                data-testId="handleBackButton"
                onClick={this.handleBackButton}>
                  {configJSON.back}
                </Button>
                <Button data-testId="handleProceed" onClick={this.handleProceed} className="proceedBtn">
                {configJSON.proceed}
                </Button>
              </Box>
            </Paper>
          </ConfirmModal>
        </>
      );
    };

  renderSendToVendor = () => {
    const selectedCount = this.state.selectedValues.length;


    return (
      <>
        <VendorModal open={this.state.openModal}>
          <Paper className="ventorContainer">
            <Box className="reasonModalHeader">
              <Typography className="emailHeading">
                {configJSON.emailReport}
              </Typography>
            </Box>
            <Box style={{ padding: "0px 20px 20px 20px" }}>
              <Box style={webStyle.textfieldContainer}>
                <label className="labelName">To</label>
                <TextField
                  fullWidth
                  variant="outlined"
                  style={webStyle.emailName}
                  data-testId="handleChangeEmail"
                  value={this.state.emailName}
                  onChange={this.handleChangeEmail}
                  onClick={this.handleCheckBox}
                  placeholder={configJSON.emailPlaceholder}
                />
                <Typography className="emailError">{this.state.invalidEmail}</Typography>
              </Box>
              <Box style={webStyle.textfieldContainer}>
                <label className="labelName">{configJSON.subject}</label>
                <TextField
                  fullWidth
                  variant="outlined"
                  style={webStyle.emailName}
                  data-testId="handleChangeSubject"
                  value={this.state.emailSubject}
                  onChange={this.handleChangeSubject}
                  onClick={this.handleCheckBox}
                  placeholder={configJSON.emailSubject}
                />
              </Box>
              <label className="labelName">{configJSON.message}</label>
              <Box style={{ marginBottom: "10px"}}>
                <TextareaAutosize
                  style={webStyle.message}
                  aria-label="minimum height"
                  minRows={4}
                  data-testId="handleChangeMessage"
                  value={this.state.emailMessage}
                  onChange={this.handleChangeMessage}
                  onClick={this.handleCheckBox}
                  placeholder={configJSON.emailMessage}
                  className="customTextarea"
                />
              </Box>
              <label className="labelName">{configJSON.discreHeading}</label>
              <Box>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={
                    selectedCount > 0 ? `${selectedCount} Selections` : ""
                  }
                  style={webStyle.checkStyle}
                  data-testId="handleMenuClick1"
                  onClick={this.handleMenuClick}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          {" "}
                          <ExpandMoreIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {this.state.isOptionMenu &&
                  <Paper className="menuContainer" style={{position:"relative"}}>
                    {
                      this.state.showOverCharges &&
                      <Box className="optionsList" data-testId="handleMenuClick" onClick={() => this.handleSelect("overcharge_only")}>
                      <Checkbox className="selectedCheck" icon={<img src={checkBox} />} checked={this.state.selectedValues.includes(
                        "overcharge_only"
                          )}/>
                        <Typography>{configJSON.overchargeRep}</Typography>
                      </Box>
                    }
                    {
                      this.state.showUnderCharges &&
                      <Box className="optionsList" data-testId="handleMenuClick2" 
                      onClick={() => this.handleSelect("over_and_undercharge")}
                      >
                     <Checkbox className="selectedCheck"   icon={<img src={checkBox} />}                   
                         checked={this.state.selectedValues.includes("over_and_undercharge")}
                         />
                       <Typography >{configJSON.includeUndercharge}</Typography>
                     </Box>
                    }
                   
                     
                      <Box data-testId="handleMenuClick3" onClick={() => this.handleSelect("quote_highlighting")} className="optionsList">
                        <Checkbox className="selectedCheck"  icon={<img src={checkBox} />} checked={this.state.selectedValues.includes(
                        "quote_highlighting"
                          )}/>
                      <Typography>{configJSON.quoteHighlight}</Typography>
                    </Box>
                    <Box onClick={() => this.handleSelect("invoice_highlighting")} className="optionsList">
                        <Checkbox className="selectedCheck"  icon={<img src={checkBox} />} checked={this.state.selectedValues.includes(
                        "invoice_highlighting"
                          )}/>
                      <Typography>{configJSON.invoiceHighlight}</Typography>
                    </Box>
                  </Paper>}
              </Box>
            </Box>
            <Box style={{ textAlign: "center", marginBottom: "30px" }}>
              <Button data-testId="emailClose" className="cancelBtn" onClick={this.handleModalClose}>
                {configJSON.modelCancleButton}
              </Button>
                  <Button  onClick={this.handleOpenConfirmModal} 
                disabled={this.state.isDisableBtn}
                data-testId="handleOpenConfirmModal"
                className={this.state.isDisableBtn ? "disableSendBtn" : "sendBtn"}>{configJSON.send}</Button>
            </Box>
          </Paper>
        </VendorModal>
      </>
    )
  }

  renderSendVentor = () => {
    return (
      <>
        <Box className="vendorContainer">
          <Typography className="venderHeading">{configJSON.venderHeading}</Typography>
          <Box className="vendorSection">
            <Grid container spacing={2}>
              <Grid item md={2} sm={2}>
              <Typography className="dataHeading">{configJSON.dataHeading}</Typography>
              <Box>
                <Box className="changeTab">
                  {this.state.vendorDetails.map((data, keyItem) => {
                    return (
                      <>
                        <Tabs
                          value={this.state.tabValue}
                          data-testId="handleSendTab"
                          onChange={()=>{this.handleListTab(keyItem,keyItem)}}
                        >
                          <Tab
                            key={keyItem}
                            label= {
                              <Box className={this.state.activeTab === keyItem ? "activeTabData" : "dateDetails"} >
                                <Typography className="dateText">{data.sent_at_date}</Typography>
                                <Typography className="timeText">{data.sent_at_time}</Typography>
                              </Box>
                            }
                            value={1}
                          ></Tab>
                        </Tabs>
                      </>
                    );
                  })}
                </Box>
                </Box>
              </Grid>
              <Grid item md={10} sm={10} className="vendorData">
                <Box className="detailsSection">
                  {this.state.vendorDetails.map((data, indexItem) => {
                    return (
                      <>
                        <TabPanelTest
                          key={indexItem}
                          value={this.state.tabValue}
                          index={indexItem}>
                          <Typography className="messagesHeading">{configJSON.viewHeading}</Typography>
                          <Box style={webStyle.subjectStyle}>
                          <Grid container spacing={2}>
                            <Grid item md={6}>
                              <Typography className="recipientHeading">
                                {configJSON.recipientHeading}
                              </Typography>
                              <Typography className="recipientName">{data.vendor_name}
                              </Typography>
                              <Typography className="recipientEmail">{configJSON.recipientEmail}</Typography>
                              <Typography className="recipientName">{data.to}</Typography>
                            </Grid>
                            <Grid item md={6}>
                              <Typography className="recipientHeading">{configJSON.recipientSubject}</Typography>
                              <Typography className="recipientName">
                                {data.subject}
                              </Typography>
                              <Typography className="recipientEmail">{configJSON.dispHeading}</Typography>
                              <Typography className="recipientName">{data.total_discrepancy}</Typography>
                            </Grid>
                          </Grid>
                          </Box>
                          <Box>
                            <Typography className="messageHeading">{configJSON.message}</Typography>
                            <Box
                              style={webStyle.mesHeading}
                            >
                              <Typography className="emailPara">
                                {data.message}
                              </Typography>
                            </Box>
                            <Typography className="messageHeading">{configJSON.attachments}</Typography>
                            <Box
                              style={webStyle.attachmentsHeading}
                            >                                                         
                              {
                                data.attachments && data.attachments.map((dataItem) => {
                                  return (
                                    <>
                                      <Box style={webStyle.pdfNameWrapper}>
                                        <Box className="attachmentSection">
                                          <Typography className="attachmentContent">
                                            {dataItem.name}
                                          </Typography>
                                          <Typography className="attachmentDetail">{dataItem.size}</Typography>
                                        </Box>
                                        <Box>
                                          <Button variant="outlined" data-testId="previewBtn" className="previewBtn" onClick={()=>this.handlOpenPdf1(dataItem.name,dataItem.url)}>
                                            {configJSON.previewBtn}
                                          </Button>
                                         <Button variant="outlined" className="downloadButton" data-testId="downloadButtonPreview" onClick={()=>this.previewDownload(dataItem.name,dataItem.url)}>
                                            {configJSON.downloadButton}
                                          </Button>
                                        </Box>
                                      </Box>
                                    </>
                                )})}
                               </Box>     

                            </Box>
                        </TabPanelTest>
                      </>
                      );
                    })}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </>
    );
  };

  renderDownloadSummaryModal = () => {
    return (
      <>
        <Box>
          <Dialogs
            open={this.state.dowmloadModal}
            data-testId="handleCloseDownloadModal"
            onClose={this.handleCloseDownloadModal}
          >
            <Box className="flexBody">
              <DialogTitle className="quote">
               {configJSON.downloadpage}
              </DialogTitle>
            </Box>
            <FormControl fullWidth>
              <Box>
                <Box className="report">{configJSON.discreHeading}</Box>
              </Box>
              <Box className="border">
                <Select
                  className="select"
                  placeholder="Select"
                  data-testId="handleSummary"
                  fullWidth
                  IconComponent={ExpandIconStyle}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "center"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "center"
                    },
                    PaperProps: {
                      style: {
                        boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
                        marginTop: "1rem",
                      },
                    },
                    getContentAnchorEl: null,
                  }}
                  value={this.state.selectedOption}
                 onChange={this.handleSelectChange}
                >
                  <MenuListItems value={configJSON.overcharge}>
                    {configJSON.overcharge}
                  </MenuListItems>
                  <MenuListItems value={configJSON.undercharge}>
                    {configJSON.undercharge}
                  </MenuListItems>
                </Select>
              </Box>
            </FormControl>
            <DialogActions>
              <Button
                color="primary"
                className="cancelBtn"
                data-testId="handleCloseDownloadModal"

                onClick={this.handleCloseDownloadModal}
              >
                {configJSON.modelCancleButton}
              </Button>
              <Button
                color="primary"
                onClick={this.handlOpenPdf}
                className={this.state.selectedOption ? "downloadBtn" : "disableDownload"}
                data-testId="handlOpenPdf"
                disabled={!this.state.selectedOption}
              >
                {configJSON.pdfGenerate}
              </Button>
            </DialogActions>
          </Dialogs>
        </Box>
      </>
    );
  };

  renderGeneratePdf = () => {
    return (
      <>
        <Box>
          <Modal open={this.state.showPdf} onClose={this.handleClosePdf}>
            <>
              <Box style={webStyle.pdfContainer}>
                <Typography style={webStyle.pdfWrapper}>{this.state.pdfName} </Typography>
                <Box style={{display: "flex", alignItems:" center"}}>
                  <CloseOutlinedIcon style={webStyle.closePdf} data-testId="handleClosePdf" onClick={this.handleClosePdf} />
                  <img src={download} style={webStyle.downloadPdf}  data-testId="summaryPdfDownload" onClick={this.summaryPdfDownload} />
                </Box>
              </Box>
              <Box>
                <Box style={webStyle.showPdf}>
                  <Paper className="pdfContainer" style={webStyle.pdfStyle}>
                    <Box style={webStyle.iframeStyle}>
                      <iframe style={webStyle.pdfViewer}
                        src={this.state.pdfUrl}
                        className="pdfViewer"
                      />
                    </Box>
                  </Paper>
                </Box>
              </Box>
            </>
          </Modal>
        </Box>
      </>
    );
  };

  previewPdf=()=>{
      return (
        <>
          <Box>
            <Modal open={this.state.showPreviewPdf} onClose={this.previewPdfClose}>
              <>
                <Box style={webStyle.pdfContainer}>
                  <Typography style={webStyle.pdfWrapper}>{this.state.previewName} </Typography>
                  <Box style={{display: "flex", alignItems:" center"}}>
                    <CloseOutlinedIcon style={webStyle.closePdf} data-testId="previewPdfClose" onClick={this.previewPdfClose} />
                    <img src={download} style={webStyle.downloadPdf}  data-testId="previewPdfDownload" onClick={this.previewPdfDownload} />
                  </Box>
                </Box>
                <Box>
                  <Box style={webStyle.showPdf}>
                    <Paper className="pdfContainer" style={webStyle.pdfStyle}>
                      <Box style={webStyle.iframeStyle}>
                        <iframe style={webStyle.pdfViewer}
                          className="pdfViewer"
                          src={this.state.previewUrl}
                        />
                      </Box>
                    </Paper>
                  </Box>
                </Box>
              </>
            </Modal>
          </Box>
        </>
      );
    };

      

  renderToastMessage = () => {
    return (
      <>
        <Snackbar
          open={this.state.toastMessage}
          onClose={this.handleCloseToastMessage}
          data-testId="handleCloseToastMessage"
          autoHideDuration={5000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MuiAlert
            style={webStyle.successAlert}
            icon={<img src={sucess} />} 
            severity="success"
          >
            {configJSON.sendEmail}
          </MuiAlert>
        </Snackbar>
      </>
    );
  };
    // Customizable Area End
    render() {
        // Customizable Area Start 
        const { navigation, handleSaveData } = this.props

        const { displayText, backgroundColor } = this.getDiscrepancyInfo();
        return (
            <>
                <Box>
                    <Header
                        navigation={navigation}
                        handleSaveData={handleSaveData}
                        history={this.props.history}
                    />
                    <Grid
                        container
                        style={webStyle.fontColor}
                    >
                        <Grid item xs={12} md={6} lg={6}>
                        {this.viewReportDetailsBreadCrumb()}
                        </Grid>
                    </Grid>
                    <InvoiceDetails>
                <Box className='discrepancyDetails'>
                    <Typography className="summaryHeaing">{configJSON.discrepancy}</Typography>
                    <Typography className='details'>{configJSON.viewHeading}</Typography>
                    <Grid container spacing={3}>
                        <Grid item lg={4} md={6} sm={6}>
                            <Box>
                                <label className='labelName'>{configJSON.modelProject}</label>
                                <TextField
                                    variant='outlined'
                                    fullWidth
                                    className="inputField"
                                    value={this.state.dummyData.project}
                                    InputProps={{ readOnly: true }}
                                />
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={6} sm={6}>
                            <Box>
                                <label className='labelName'>{configJSON.modelReport}</label>
                                <TextField
                                    variant='outlined'
                                    fullWidth
                                    className="inputField"
                                    value={this.state.dummyData.report}
                                    InputProps={{ readOnly: true }}
                                />
                            </Box>
                        </Grid>
                        <Grid item lg={4} md={6} sm={6}>
                            <Box>
                                <label className='labelName'>{configJSON.vendorTitle}</label>
                                <TextField
                                    variant='outlined'
                                    fullWidth
                                    className="inputField"
                                    value={this.state.dummyData.vendor}
                                    InputProps={{ readOnly: true }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item lg={4} md={6}>
                            <Box className="discrepancySection">
                                <label className='labelName'>{configJSON.totalDiscrepancy}</label>
                                <TextField
                                    variant='outlined'
                                    fullWidth
                                    className="inputField"
                                    value={displayText}
                                    InputProps={{ readOnly: true }}
                                    style={{ backgroundColor }}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                        <Box style={{margin: "40px"}} >
                            <Box className="quoteWrapper">
                                <Typography className="itemDetails">{configJSON.itemdetailsContent}</Typography>
                                <Box className="tableWrapper">
                                    <Typography className="discreHeading">{configJSON.discreHeading}</Typography>
                                    <Select
                                        className="selectWrapper"
                                        labelId="demo-simple-select-disabled-label"
                                        id="demo-simple-select-disabled"
                                        input={<OutlinedInput />}
                                        displayEmpty
                                        fullWidth
                                        placeholder="Please select vendor"
                                        value={this.state.selectedValue}
                                        onChange={this.handleChange} 
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                        IconComponent={ExpandIcon}
                                        data-testId={"projectTestID"}
                                    >
                                      {
                                        this.state.showUnderCharges &&
                                        <MenuItem value="Both">Both</MenuItem>

                                      }
                                        {
                                          this.state.showOverCharges &&
                                          <MenuItem value="overcharges">{configJSON.overcharged}</MenuItem>
                                        }
                                    </Select>
                                </Box>
                            </Box>
                            <Box className="mainContainer">
                                <Box className="nameHeading">
                                    <Box className="nameItemHeading">
                                        <Typography className="itemsHeading">{configJSON.itemsHeading}</Typography>
                                    </Box>
                                    {this.state.viewListData && this.state.viewListData.map((item) => {
                                        return (
                                            <>
                                                <Box className="itemNameHead">
                                                    <Typography className="name">{item?.quote.product}</Typography>
                                                </Box>
                                            </>
                                        )
                                    })}

                                </Box>
                                <Box>
                                    <Typography className="quoteHeading">{configJSON.quoteText}</Typography>
                                    {this.invoiceTable()}
                                </Box>
                                <Box>
                                    <Box>
                                        <Typography className="quoteHeading">{configJSON.invoiceText}</Typography>
                                        {this.quoteTable()}
                                    </Box>
                                </Box>

                                <Box className="nameHeading">
                                    <Box className="discrepancyHeading">
                                        <Typography className="heading">{configJSON.discrepency}</Typography>
                                    </Box>
                                    {this.state.viewListData && this.state.viewListData.map((item) => {
                                        return (
                                            <>
                                                <Box className="itemName">
                                                    <Box className="discrepencyDetails">
                                                        <Chip label={item.type} className={item.type === "Overcharged" ? "overChanged" : "underChanged"} />
                                                        <Typography className='invoicePrice'>${item.total}</Typography>
                                                    </Box>
                                                </Box>
                                            </>
                                        )
                                    })}
                                </Box>

                </Box>
                <Box className="btnSection">
                    <Button variant="contained" className="downloadBtn" startIcon={<img src={upload_image} />}   data-testId="handleOpenDownloadModal" onClick={this.handleOpenDownloadModal}>
                        {configJSON.downloadSummary}
                    </Button> 
                    <Button data-testId="sendEmail" variant="contained" className="vendorButton" startIcon={<img src={sendIcon} />} onClick={this.handleModalOpen} >
                        {configJSON.sendVendor}
                    </Button>
                </Box>
                </Box>
                {this.renderSendToVendor()}
                {this.renderDownloadSummaryModal()}
                {this.renderGeneratePdf()}
                {this.previewPdf()}
                {this.renderEmailConfirm()}
                {this.state.vendorDetails.length > 0 && this.renderSendVentor()}
                {this.renderToastMessage()}
            </InvoiceDetails>
                </Box>
            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
const ExpandIcon = styled(ExpandMoreIcon)({
    marginRight: "15px",
    color: "#05052B"
});
const ExpandIconStyle = styled(ExpandMoreIcon)({
  marginRight: "15px",
  color: "#05052B",
});
const InvoiceDetails = styled(Box)({
  background: "#F6F7FA",
  "& .vendorContainer": {
    paddingLeft: "50px",
    paddingRight: "50px"
  },
  "& .venderHeading": {
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "27px",
    color: "#05052B",
    marginLeft: "28px",
    marginBottom: "30px"
  },
  "& .dateText": {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "14px"
  },
  "& .timeText": {
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "14px",
    marginTop: "5px",
    textAlign: "start"
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "white"
  },
  "& .messageHeading": {
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: "Poppins",
    lineHeight: "24px",
    marginTop: "36px",
    marginBottom: "12px",
    color: "#05052B"
  },
  "& .messagesHeading": {
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: "Poppins",
    lineHeight: "24px",
    margin: "12px 0px 12px 0px",
    color: "#05052B"
  },
  "& .vendorSection": {
    background: "white",
    borderRadius: 12
  },
  "& .detailsSection": {
    borderLeft: "1px solid #F2F2F2",
    padding: "0px 0px 0px 10px",
    height:"1000px",
    overflow: "auto",
    marginLeft: "50px"
  },
  "& .vendorData": {
    padding: "0px"
  },
  "& .previewBtn": {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "12px",
    lineHeight: "18px",
    border: "1px solid #05052B",
    textTransform: "capitalize"
  },
  "& .recipientName": {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#05052B"
  },
  "& .attachmentDetail": {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "10px",
    lineHeight: "10px",
    color: "#87888F",
    marginTop: "10px"
  },
  "& .recipientHeading": {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "10px",
    lineHeight: "15px",
    color: "#979797",
    textTransform: "uppercase"
  },
  "& .recipientEmail": {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "10px",
    lineHeight: "15px",
    color: "#979797",
    textTransform: "uppercase",
    marginTop: "15px"
  },
  "& .emailPara": {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Poppins",
    lineHeight: "24px",
    color: "#05052B",
    whiteSpace: "pre-line"
  },
  "& .attachmentContent": {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "14px",
    color: "#05052B"
  },
  "& .attachmentSection": {
    borderBottom: "1px solid #F2F2F2",
    padding: "0px 0px 25px 20px",
    width: "70%"
  },
  "& .downloadButton": {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "12px",
    lineHeight: "18px",
    border: "1px solid #05052B",
    textTransform: "capitalize",
    marginLeft: "15px"
  },
  "& .dataHeading": {
    fontWeight: 600,
    fontSize: "14px",
    fontFamily: "Poppins",
    lineHeight: "24px",
    margin: "15px 0px 0px 30px",
    color: "#0F172A"
  },
  "& .selectedItems": {
    fontWeight: 400,
    fontSize: "16px",
    fontFamily: "Poppins",
    lineHeight: "24px",
    color: "#05052B"
  },
  "& .mainContainer": {
    display: "grid",
    gridTemplateColumns: "1fr 2fr 2fr 1fr",
    gap: "10px",
    marginTop: "20px",
    overflowX: "auto" as "auto",
    width: "100%"
  },
  "& .itemHeading": {
    backgroundColor: '#FFF',
    fontWeight: 'bold',
    marginBottom: "10px",
    padding: "19px",
    paddingBottom: "10px"
  },
  "& .discrepancyHeading": {
    backgroundColor: '#FFF',
    fontWeight: 'bold',
    marginBottom: "10px",
    padding: "19px",
    paddingBottom: "10px",
    height: "70px",
    borderRadius: "4px"
  },
  "& .nameItemHeading": {
    backgroundColor: '#FFF',
    fontWeight: 'bold',
    marginBottom: "10px",
    padding: "19px",
    paddingBottom: "10px",
    height: "70px",
    borderRadius: "4px"
  },
  "& .itemName": {
    backgroundColor: '#FFF'
  },
  "& .itemNameHead": {
    backgroundColor: '#FFF',
    cursor: 'pointer',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)"
  },
  "& .name": {
    padding: "15px",
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#05052B",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "180px"
  },
  "& .invoiceData": {
    display: "grid",
    gridTemplateColumns: "repeat(4, 2fr)",
    gap: "10px",
    backgroundColor: '#FFF',
    padding: "15px",
    marginBottom: "10px",
    textAlign: "center"
  },
  "& .inputField": {
    marginTop: "10px",
    "& .MuiOutlinedInput-input": {
      color: "black"
    },
    '& .MuiOutlinedInput-root': {
      pointerEvents: 'none'
    },
  },
  "& .nameHeading": {
    marginTop: "37px"
  },
  "& .dateDetails": {
    padding: "8px",
    background: "#FFF",
    color: "#0F172A",
    borderRadius: "8px",
    textTransform: "capitalize",
    textAlign: "start",
    width: "160px"
  },
  "& .activeTabData": {
    padding: "8px",
    background: "#FFF7F0",
    color: "#DF802D",
    borderRadius: "8px",
    textTransform: "capitalize",
    textAlign: "start",
    width: "160px"

  },
  "& .changeTab": {
    width: "208px",
    padding: "12px",
    height: "900px",
    overflow: "auto"
  },
  "& .invoiceDetails": {
    display: "grid",
    gridTemplateColumns: "repeat(4, 2fr)",
    backgroundColor: '#FFF',
    padding: "15px",
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#05052B",
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)"
  },
  "& .quoteDetails": {
    display: "grid",
    gridTemplateColumns: "repeat(4, 2fr)",
    backgroundColor: '#FFF',
    padding: "15px",
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#05052B",
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)"
  },
  "& .invoiceItems": {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#05052B"
  },
  "& .successAlert": {
    fontWeight: 500,
    fontSize: "16px",
    fontFamily: "Poppins",
    lineHeight: "24px",
    color: "#0F172A",
    background: "white"
  },
  "& .invoicePrice": {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#05052B",
    paddingRight: "20px"
  },
  "& .discrepencyDetails": {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    padding: "10.6px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)"
  },
  "& .overChanged": {
    background: '#FF6666',
    color: 'white',
    width: "127px",
    height: "30px"
  },
  "& .underChanged": {
    background: '#FFD966',
    color: 'white',
    width: "127px",
    height: "30px"
  },
  "& .heading": {
    fontWeight: 500,
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#05052B",
    width: "70px"
  },
  "& .itemsHeading": {
    fontWeight: 500,
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#05052B",
    width: "100px"
  },
  "& .details": {
    fontWeight: 500,
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#05052B",
    marginBottom: '15px'
  },
  "& .summaryHeaing": {
    fontWeight: 500,
    fontFamily: "Poppins",
    fontSize: "18px",
    lineHeight: "27px",
    color: "#05052B",
    padding: "25px 0 25px 0"
  },
  "& .discrepancyDetails": {
    background: "white",
    padding: "30px",
    borderRadius: "20px",
    marginTop: "20px"
  },
  "& .labelName": {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#05052B",
    marginBottom: "5px"
  },
  "& .discrepancySection": {
    marginTop: "20px"
  },
  "& .itemDetails": {
    fontWeight: 500,
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#05052B",
    marginTop: "50px"
  },
  "& .vendorButton": {
    background: "black",
    color: "white",
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "21px",
    textTransform: "capitalize",
    height: "45px"
  },
  "& .downloadBtn": {
    background: "black",
    color: "white",
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "21px",
    textTransform: "capitalize",
    marginRight: '10px',
    height: "45px"
  },
  "& .btnSection": {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: '20px'
  },
  "& .quoteHeading": {
    fontWeight: 600,
    fontFamily: "Poppins",
    fontSize: "14px",
    lineHeight: "21px",
    textAlign: "center",
    marginBottom: "15px",
    color: "#05052B",
    borderRadius: "4px"
  },
  "& .quoteWrapper": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  "& .selectWrapper": {
    background: "white",
    borderRadius: "15px",
    marginTop: "10px"
  },
  "& .tableWrapper": {
    width: "30%",
    marginTop: "20px",
    borderRadius: "10px"
  },
  "& .discreHeading": {
    marginRight: '10px'
  },
  "& .overChangedItem": {
    color: "#FF6666"
  },
  "& .underChangedItem": {
    color: "#FFD966"
  },
})

const UploadReportContainer = styled(Box)({
  cursor: "pointer",
  "&:hover": {
    color: "#1d6dcb",
    textDecoration: "underline"
  }
});

const webStyle = {
  breadcrumbStyle: {
    color: "orange"
  },
  subjectStyle: {
    borderBottom: "1px solid #F2F2F2",
    paddingBottom: "20px"
  },
  fontColor: {
    color: 'black',
    alignItems: "center"
  },
  successAlert: {
    lineHeight: "24px",
    color: "#0F172A",
    background: "white",
    fontFamily: "Poppins",
    width: "245px"
  },
  breadcrumb: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "150px",
    fontWeight: 500,
    textDecoration: "underline",
    color: "#87888F"
  } as React.CSSProperties,
  reportUnderStyle: {
    fontWeight: 500,
    textDecoration: "underline",
    color: "#87888F"
  },
  emailName: {
    marginTop: "5px",
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px"
  },
  textfieldContainer: {
    marginTop: "20px",
    marginBottom: "25px"
  },
  message: {
    width: "100%",
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#05052B",
    height: "80px",
    overflow: "auto",
    border: "1px solid #BEBEBE",
    borderRadius:"4px",
    padding: "10px",
    marginTop: "5px",
    marginBottom: "20px",
    outline: "none",
    transition: "border-color 0.2s ease",
    resize: "vertical" as "vertical" 
  },
  checkStyle: {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "16px",
    marginTop: "5px",
    lineHeight: "24px"
  },
  mesHeading: {
    border: "1px solid #F2F2F2",
    borderRadius: "15px",
    padding: "15px"
  },
  attachmentsHeading: {
    border: "1px solid #F2F2F2",
    borderRadius: "15px",
    padding: "10px",
    height: "30%",
    overflow: "scroll"
  },
  pdfNameWrapper: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px"
  },
  pdfContainer: {
    background: '#05052B',
    padding: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backdropFilter: "blur(7px)"
  },
  pdfWrapper: {
    color: "white",
    fontWeight: 500,
    fontFamily: "Poppins",
    fontSize: "12px",
    marginLeft: "15px",
    lineHeight: "14px"
  },
  showPdf: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backdropFilter: "blur(7px)",
    overflowY: "auto",
    width: "100%",
    padding: "20px 0px 0px 20px"
  } as React.CSSProperties,

  pdfStyle: {
    maxWidth: "600px",
    width: "100%",
    height: "600px"
  },
  pdfViewer: {
    width: "600px",
    height: "600px",
    display: "block",
    marginBottom: "20px"
  },
  downloadPdf: {
    marginRight: "15px",
    cursor: 'pointer',
  },
  closePdf: {
    cursor: 'pointer',
    marginRight: "15px",
    color: "white"
  },
  iframeStyle: {
    height: "600px"
  }
};


const VendorModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflowY: "scroll",
  backdropFilter: "blur(7px)",
  padding: "20px 0px 0px 20px",
  "& .ventorContainer": {
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    width: "100%",
    maxWidth: "600px",
    maxHeight: "621px",
    overflowY: "scroll",
    "& .MuiPopover-paper": {
      outline: "1px solid red"
    },
  },
  "& .reasonModalHeader": {
    width: "85%"
  },
  "& .MuiCheckbox-colorSecondary.Mui-checked": {
    color: "#DF802D"
  },
  "& .MuiIconButton-colorSecondary": {
    color: "#DF802D"
  },
  "& .emailHeading": {
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "27px",
    color: "#05052B",
    padding: "22px 0px 0px 20px"
  },
  "& .labelName": {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "16px",
    color: "#05052B"
  },
  "& .customTextarea:focus": {
    border: "2px solid #3F50B5 !important", 
    outline: "none"  
  },
  "& .emailError": {
    fontWeight: 400,
    fontFamily: "Poppins",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#FB5D5D"
  },
  "& .cancelBtn": {
    border: "1px solid #05052B",
    width: "125px",
    height: "42px",
    marginRight: "20px",
    textTransform: "capitalize"
  },
  "& .disableSendBtn": {
    background: "#05052B69",
    width: "125px",
    color: "white",
    height: "42px",
    marginRight: "20px",
    textTransform: "capitalize"
  },
  "& .sendBtn": {
    background: "#05052B",
    width: "125px",
    color: "white",
    height: "42px",
    marginRight: "20px",
    textTransform: "capitalize"
  },
  "& .menuContainer": {
    borderRadius: "15px",
    padding: "5px 15px 5px 5px",
    width: "100%",
    overflow: "auto",
    position: "absolute",
    zIndex: 1,
    maxHeight: "120px"
  },
  "& .optionsList": {
    display: "flex",
    alignItems: "center"
  },
  "& .optionsSubList": {
    display: "flex",
    alignItems: "center",
    paddingLeft: "30px"
  },
});
  
  const Dialogs = styled(Dialog)({
    backdropFilter: "blur(7px)",
    "& .MuiDialog-paper": {
      maxWidth: "550px",
      width: "100%",
      color: "#fff",
      backgroundColor: "#fff",
      padding: "40px",
      scrollBehavior: "auto",
      borderRadius: "10px",
      height: "295px"
    },
    "& .flexBody": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    "& .quote": {
      color: "#05062a",
      padding: "0px",
      "& .MuiTypography-h6": {
        fontSize: "18px",
        fontWeight: 500,
        fontFamily: "Poppins",
        lineHeight: "27px"
      },
    },
    "& .report": {
      color: "#05062a",
      paddingTop: "20px",
      fontSize: "14px",
      fontWeight: 500,
      fontFamily: "Poppins",
      lineHeight: "27px"
    },
    "& .select": {
      width: "100%",
      marginTop: "0.5rem"
    },
    "& .MuiSelect-nativeInput": {
      width: "90%",
      color: "#05052B",
      opacity: "inherit",
      border: "none",
      bottom: "auto",
      paddingLeft: "15px",
      fontSize: "17px",
      fontWeight: 500,
      fontFamily: "Poppins",
      "@media (max-width: 600px)": {
        width: "75%"
      },
    },
    "& .MuiInput-underline": {
      borderBottom: "none",
      "&:hover:not(.Mui-disabled):before": {
        transform: "none",
        borderBottom: "none"
      },
      "&:before": {
        content: "none",
        borderBottom: "none",
        "&:hover": {
          borderBottom: "none"
        },
      },
      "&:after": {
        transform: "none",
        borderBottom: "none",
        "&:hover": {
          borderBottom: "none"
        },
      },
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    },
    "& .MuiSvgIcon-root": {
      width: "2em",
      color: "#292D32"
    },
    "& .MuiMenu-paper": {
      top: "236px !important"
    },
    "& .border": {
      height: "60px",
      border: "1.5px solid #d4c7c7",
      borderRadius: "5px",
      display: "flex"
    },
    "& .MuiDialogActions-spacing": {
      padding: "0px",
      paddingTop: "30px",
      "@media (max-width: 600px)": {
        flexDirection: "column"
      },
    },
    "& .cancelBtn": {
      border: "1.5px solid #05062a",
      width: "48%",
      borderRadius: "5px",
      color: "#05062a",
      fontWeight: 500,
      fontSize: "16px",
      textTransform: "capitalize",
      fontFamily: "Poppins",
      marginRight: "15px",
      "@media (max-width: 600px)": {
        width: "100%",
      },
    },
    "& .downloadBtn": {
      borderRadius: "5px",
      width: "50%",
      backgroundColor: "#05062a",
      border: "1px solid navy",
      color: "white",
      fontWeight: 500,
      fontSize: "16px",
      fontFamily: "Poppins",
      textTransform: "capitalize",
      "@media (max-width: 600px)": {
        width: "100%",
        marginLeft: "0px",
        marginTop: "5px"
      },
    },
    "& .disableDownload": {
      backgroundColor: "#05052B69",
      borderRadius: "5px",
      color: "white",
      fontWeight: 500,
      fontSize: "16px",
      fontFamily: "Poppins",
      width: "50%",
      textTransform: "capitalize",
      "@media (max-width: 600px)": {
        width: "100%",
        marginLeft: "0px",
        marginTop: "5px"
      },
    },
    "& .MuiMenu-list ": {
      backgroundColor: "red !important"
    },
  });
  const MenuListItems = styled(MenuItem)({
    lineHeight: "27px",
    fontFamily: "Poppins",
    fontWeight: 500,
    width: "100%",
    fontSize: "16px",
    minWidth: "460px",
    "&:hover": {
      backgroundColor: "#dd7f38",
      color: "white"
    },
    "& .MuiPaper-root MuiMenu-paper MuiPopover-paper MuiPaper-elevation8 MuiPaper-rounded": {
      top: "550px"
    }
  })

  const ConfirmModal = styled(Modal)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflowY: "auto",
    backdropFilter: "blur(7px)",
    "& .emailContainer": {
      display: "flex",
      flexDirection: "column",
      borderRadius: "10px",
      width: "100%",
      maxWidth: "410px",
      padding: "40px 0 40px 40px"
    },
    "& .emailHeader": {
      fontSize: 18,
      lineHeight: "27px",
      color: "#000000",
      fontFamily: "Poppins",
      fontWeight: 500,
      width: "346px"
    },
    "& .backBtn": {
      height: "42px",
      width: "125px",
      fontSize: "14px",
      lineHeight: "21px",
      border: "1px solid #05052B",
      marginRight: "20px",
      color: "#05052B",
      textTransform: "capitalize",
      fontFamily: "Poppins"
    },
    "& .buttonBox": {
       textAlign: "center"
    },
    "& .confirmDetails": {
      fontSize: 14,
      fontWeight: 500,
      color: "#05052B",
      lineHeight: "21px",
      fontFamily: "Poppins"
    },
    "& .confirmBox": {
      padding: "20px 0 20px 0"
    },
    "& .proceedBtn": {
      width: "125px",
      color: "white",
      height: "42px",
      marginRight: "20px",
      background: "#05052B",
      textTransform: "capitalize"
    },
  })
// Customizable Area End