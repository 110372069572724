// Customizable Area Start
import React from "react";
import {
    Box, Switch, FormControl, FormLabel, FormControlLabel,
    Grid, Button, Checkbox, Modal, RadioGroup, Radio,
    Typography, TextField, Table, TableHead, TableRow, TableCell, Breadcrumbs, Link, TableBody
} from "@material-ui/core";
import Pagination from '@material-ui/lab/Pagination';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { withStyles,styled } from "@material-ui/core/styles";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import Header from "../../landingpage/src/Header.web";
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';
import moment from "moment";
import { NumericFormat } from "react-number-format";
import EditQuoteScanResultController, {
    Props, AddSegmentBoxProps,
    NoItemBoxProps,
    AddSegmentButtonProps, QuoteDateBoxProps, QuoteSiteBoxProps,
    NoDataTableRowProps, MonthRentalTableProps,
    QuoteExpiryDateBoxProps, TableCellDataProps,
    DeleteButtonProps, configJSON, DayRentalTableProps, RentalTableCellDataProps,
    TotalAmountBoxProps, QuoteTableHeadProps, QuoteTableCellDataProps
} from "./EditQuoteScanResultController.web";
import { deletePng,calendarImage } from "./assets";
import DeleteModal from "./DeleteModal.web";
// Customizable Area End

// Customizable Area Start


export const AddSegmentDataBox = (props: AddSegmentBoxProps) => {
    const { addNewSegmentVal, classes, addNewItemRowData, subTotalDataValue, columnIdVal } = props

    return (
        <Box>
            {
                addNewSegmentVal ?
                    <Box className={classes.addItemSegmentBox}>
                        <Button onClick={() => addNewItemRowData(columnIdVal)} data-testid={`addNewItemRowTestId-${columnIdVal}`}><AddCircleOutlineIcon />{configJSON.addNewItem}</Button>

                        <Box className={classes.editsubTotalBox}>
                            <Typography>{configJSON.invoiceCategoryTotal}</Typography>
                            {
                                subTotalDataValue === null || isNaN(subTotalDataValue as number)?
                                    <Typography>$ {'0'}</Typography> :
                                    <Typography>$ {Number(subTotalDataValue).toFixed(2)}</Typography>
                            }

                        </Box>
                    </Box> :

                    <Box className={classes.addItemSegmentBox}>
                        <Button onClick={() => addNewItemRowData(columnIdVal)} data-testid={`addNewItemRowDataId-${columnIdVal}`}><AddCircleOutlineIcon />{configJSON.addNewItem}</Button>

                        <Box className={classes.editsubTotalBox}>
                            <Typography>{configJSON.segmentContent}</Typography>
                            {
                                subTotalDataValue === null || isNaN(subTotalDataValue as number) ?
                                    <Typography style={{width:'300px'}}>$ {'0'}</Typography> :
                                    <Typography style={{width:'300px'}}>$ {Number(subTotalDataValue).toFixed(2)}</Typography>
                            }
                        </Box>
                    </Box>
            }
        </Box>
    )
}

export const NoItemDataBox = (props: NoItemBoxProps) => {
    const { classes } = props
    return (
        <>
            <Box className={classes.quoteDragBox} ><Typography>{configJSON.invoiceBoxText}</Typography>
                <Typography>{configJSON.invoiceBoxTextBold}</Typography>
            </Box>
        </>
    )
}

export const DeleteItemButton = (props: DeleteButtonProps) => {

    const { addNewSegmentVal, addCategoryVal, handleCategoryChangeName, columnIdVal, classes, categoryErrorMessageVal, deleteCategoryModal } = props
    return (
        <Box>
            {
              (addNewSegmentVal ||addCategoryVal) &&
                <Box className={classes.categoryErrorBox}>
                    <Box className={classes.addNewSegmentBox}>
                        <Typography className={classes.addNewSegmentContent}>{configJSON.invoiceCategory}</Typography>
                        <Box style={{height:'53px'}}>
                        <TextField 
                            placeholder={configJSON.invoiceEnterCategory}
                            id="category_name"
                            value={addCategoryVal}
                            data-testid="categoryid"
                            onChange={(event) => handleCategoryChangeName(event, columnIdVal)} />
                            {categoryErrorMessageVal && (<Typography className={classes.errorBox}>{categoryErrorMessageVal}</Typography>)}
                        </Box>
                        <img src={deletePng} alt="deletePng" data-testid={`deleteIconTestid-${columnIdVal}`} onClick={() => deleteCategoryModal(columnIdVal)} />
                    </Box>
                 
                </Box>
            }
        </Box>
    )
}

export const AddSegmentItemButton = (props: AddSegmentButtonProps) => {
    const { selected, addNewSegmentHandler, classes, deleteOpenModal } = props
    return (

        <Box>
            {selected && <Button className={classes.deleteButton} data-testid="deleteModalTestid" onClick={deleteOpenModal}>{configJSON.deleteSelectedButton}</Button>}
            <Button onClick={addNewSegmentHandler} data-testid="addNewsegmentid"><AddCircleOutlineIcon />{configJSON.newSegment}</Button>
        </Box>
    )
}

export const QuoteCreateDateBox = (props: QuoteDateBoxProps) => {
    const { editQuoteDetail, classes, typeOfFocused, handleDate, handleEditQuoteDetail } = props
    const dateVal = moment(editQuoteDetail.date).format("MM-DD-YYYY");
    return (
        <>
            {
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <StartDateCalendarEdit
                        autoOk
                        variant="inline"
                        disableToolbar
                        margin="normal"
                        format="MM-DD-YYYY"
                        value={dateVal}
                        onFocus={handleDate}
                        onChange={(event) => handleEditQuoteDetail("date", event)}
                        placeholder="Please enter date"
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        InputProps={{
                            disableUnderline: true,
                            style: {
                                border: '2px solid #d5d5d5',
                                borderRadius: '4px',
                            },
                        }}
                        data-testid="dateTestid"
                        className={classes.datePicker}
                        keyboardIcon={<img src={calendarImage} alt="fromimage" />
                        }
                    />
                </MuiPickersUtilsProvider>
            }
        </>
    )
}

export const QuoteSiteDataBox = (props: QuoteSiteBoxProps) => {
    const { editQuoteDetail, handleEditQuoteDetail } = props
    return (
        <>
            {
                editQuoteDetail.site_contact === null ? <TextField value={"Unknown"} id="site_contact" onChange={(e) => handleEditQuoteDetail('site_contact', e.target.value)} /> : <TextField value={editQuoteDetail.site_contact} id="site_contact" onChange={(e) => handleEditQuoteDetail('site_contact', e.target.value)} />
            }
        </>
    )
}

export const QuoteExpiryDateitemBox = (props: QuoteExpiryDateBoxProps) => {
    const { editQuoteDetail, classes, typeOfFocused, handleDate, handleEditQuoteDetail } = props
    const expiryDateVal = moment(editQuoteDetail.expiration_date).format("MM-DD-YYYY")
    return (
        <>
            {
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                    <StartDateCalendarEdit
                        autoOk
                        variant="inline"
                        disableToolbar
                        margin="normal"
                        format="MM-DD-YYYY"
                        value={expiryDateVal}
                        onChange={(event) => handleEditQuoteDetail("expiration_date", event)}
                        placeholder="Please enter date"
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        InputProps={{
                            disableUnderline: true,
                            style: {
                                border: '2px solid #d5d5d5',
                                borderRadius: '4px',
                            },
                        }}
                        data-testid="expiryDateTestid"
                        className={classes.datePicker}
                        keyboardIcon={<img src={calendarImage} alt="fromimage" />
                        }
                    />
                </MuiPickersUtilsProvider>
            }
        </>
    )
}


export const NoItemTableRow = (props: NoDataTableRowProps) => {
    const { classes, QuoteItemTaxData } = props
    return (
        <TableRow style={{ background: 'red' }}>
            <TableCell>
                <Checkbox
                    id="checkBox"
                />
            </TableCell>
            <TableCell className={classes.idBox}>{'1'}</TableCell>
            <TableCell className={classes.editInputField}><TextField /></TableCell>
            <TableCell className={classes.editunitFieldContent}><TextField /></TableCell>
            <TableCell className={classes.editunitFieldContent}><TextField /></TableCell>
            <TableCell><TextField /></TableCell>
            {
                QuoteItemTaxData && <TableCell><TextField /></TableCell>
            }
            <TableCell><TextField /></TableCell>
        </TableRow>
    )
}


export const TableCellData = (props: TableCellDataProps) => {
    const {
        QuoteAddNewSegmentState,
        categoryBox,
        classes,
        selected,
        handleRowItemClick,
        descriptionValue, HandleEditQuoteDescription, unitValue,
        priceValue, QuoteItemTaxData, QuoteTaxValue, amount,
        ord_quantityValue, columnId, editquoteId, begin, deleteOpenTableItemModal, index } = props

    const isSelected = (id: number) => selected.indexOf(id) !== -1;
    const isItemSelectedData = isSelected(Number(editquoteId));
    return (
        <>
            {
                QuoteAddNewSegmentState && categoryBox &&
                <TableCell padding="checkbox" className={classes.checkBox}>
                    <Checkbox
                        id="checkBox"
                        checked={isItemSelectedData}
                        onClick={(event) => { handleRowItemClick(event, columnId, editquoteId) }}
                        data-testid={`dataItemClickId-${columnId}`}
                    />
                </TableCell>
            }
            <TableCell className={classes.idBox}>{begin + index + 1}</TableCell>
            <TableCell
                className={isItemSelectedData ? classes.editInputBox : classes.editInputField}>
                <TextField data-testid="descriptionid" id="description" value={descriptionValue}
                    onChange={(e) => HandleEditQuoteDescription(e, columnId, editquoteId, 'description')}
                />
            </TableCell>
            <TableCell className={isItemSelectedData ? classes.editQuantityInputBox : classes.editunitFieldContent}>
                <NumericFormat
                    value={ord_quantityValue}
                    id="ord_quantity"
                    onChange={(e) => HandleEditQuoteDescription(e, columnId, editquoteId, 'ord_quantity')}
                    data-testid="ord_quantityid"
                    style={{ width: '100%', height: '62px' }}
                />
            </TableCell>
            <TableCell className={isItemSelectedData ? classes.editQuantityInputBox : classes.editunitFieldContent}>
                <TextField data-testid="unitid" id="unit" value={unitValue} onChange={(e) => HandleEditQuoteDescription(e, columnId, editquoteId, 'unit')} />
            </TableCell>
            <TableCell className={isItemSelectedData ? classes.editUnitPriceBox : classes.editUnitPricewidthBox}>
                <NumericFormat
                    data-testid="priceid"
                    id="price"
                    value={priceValue}
                    prefix="$ "
                    thousandSeparator=","
                    decimalSeparator="."
                    decimalScale={4}
                    onValueChange={(values) => HandleEditQuoteDescription(values.floatValue, columnId, editquoteId, 'price')}
                    customInput={TextField}
                />
            </TableCell>
            {
                QuoteItemTaxData &&
                <>
                    {
                        QuoteTaxValue === null ?
                            <TableCell className={isItemSelectedData && classes.editUnitPriceBox}>
                                <NumericFormat
                                    value={`0`}
                                    data-testid="taxid"
                                    id="tax"
                                    suffix=" %"
                                    onValueChange={(values) => HandleEditQuoteDescription(values.floatValue, columnId, editquoteId, 'tax')}
                                    customInput={TextField}
                                />
                            </TableCell>
                            : <TableCell className={isItemSelectedData && classes.editUnitPriceBox}>
                                <NumericFormat
                                    value={QuoteTaxValue}
                                    data-testid="taxid"
                                    id="tax"
                                    suffix="%"
                                    onValueChange={(values) => HandleEditQuoteDescription(values.floatValue, columnId, editquoteId, 'tax')}
                                    customInput={TextField}
                                />
                            </TableCell>
                    }
                </>

            }

            <TableCell className={isItemSelectedData && classes.editUnitPriceBox}>
                <NumericFormat
                    thousandSeparator=","
                    id="amount"
                    decimalSeparator="."
                    decimalScale={4}
                    value={amount}
                    data-testid="amountid"
                    prefix="$ "
                    onValueChange={(values) => HandleEditQuoteDescription(values.floatValue, columnId, editquoteId, 'amount')}
                    customInput={TextField}
                />
            </TableCell>
            {
                !QuoteAddNewSegmentState &&
                <TableCell>
                    <img src={deletePng} alt="deletePng" data-testid={`deleteItemTestid-${editquoteId}`} onClick={() => deleteOpenTableItemModal(editquoteId)} />
                </TableCell>
            }

        </>
    )
}

export const DayRentalTable = (props: DayRentalTableProps) => {
    const { QuoteAddNewSegmentState,
        categoryBox,
        classes,
        selected,
        handleRowItemClick, cat_classValue,
        descriptionValue, HandleEditQuoteDescription, rate_sheet_catValue,
        rate_sheet_minValue, QuoteItemTaxData, QuoteTaxValue, amount,
        rate_sheet_dailyValue, columnId, editquoteId, begin, deleteOpenTableItemModal, index } = props

    const isDayRentalSelected = (id: number) => selected.indexOf(id) !== -1;
    const isDayRentalItemSelectedData = isDayRentalSelected(Number(editquoteId));

    return (
        <>
            {
                QuoteAddNewSegmentState && categoryBox &&
                <TableCell padding="checkbox" className={classes.checkBox}>
                    <Checkbox
                        id="checkBox"
                        checked={isDayRentalItemSelectedData}
                        onClick={(event) => { handleRowItemClick(event, columnId, editquoteId) }}
                        data-testid={`dataItemClickId-${columnId}`}
                    />
                </TableCell>
            }
            <TableCell className={classes.idBox}>{begin + index + 1}</TableCell>
            <TableCell className={isDayRentalItemSelectedData ? classes.editQuantityInputBox : classes.editunitFieldContent}>
                <TextField
                    value={cat_classValue}
                    id="cat_class"
                    onChange={(e) => HandleEditQuoteDescription(e, columnId, editquoteId, 'cat_class')}
                    data-testid="ord_quantityid"
                    style={{ width: '100%', height: '62px' }}
                />
            </TableCell>
            <TableCell
                className={isDayRentalItemSelectedData ? classes.editInputBox : classes.editInputField}>
                <TextField data-testid="descriptionid" id="description" value={descriptionValue}
                    onChange={(e) => HandleEditQuoteDescription(e, columnId, editquoteId, 'description')}
                />
            </TableCell>
            <TableCell className={isDayRentalItemSelectedData ? classes.editQuantityInputBox : classes.editunitFieldContent}>
                <TextField data-testid="unitid" id="rate_sheet_cat" value={rate_sheet_catValue} onChange={(e) => HandleEditQuoteDescription(e, columnId, editquoteId, 'rate_sheet_cat')} />
            </TableCell>
                <TableCell className={isDayRentalItemSelectedData && classes.editUnitPriceBox}>
                <NumericFormat
                    thousandSeparator=","
                    decimalSeparator="."
                    decimalScale={4}
                    id="rate_sheet_min"
                    data-testid="unitid"
                    value={rate_sheet_minValue}
                    prefix="$ "
                    customInput={TextField}
                    onValueChange={(values) => HandleEditQuoteDescription(`$${values.floatValue}`, columnId, editquoteId, 'rate_sheet_min')}
                />

                </TableCell>
            <TableCell className={isDayRentalItemSelectedData && classes.editUnitPriceBox}>
                  <NumericFormat
                    thousandSeparator=","
                    decimalSeparator="."
                    decimalScale={4}
                    id="rate_sheet_daily"
                    data-testid="priceid"
                    value={rate_sheet_dailyValue}
                    prefix="$ "
                    customInput={TextField}
                    onValueChange={(values) => HandleEditQuoteDescription(`$${values.floatValue}`, columnId, editquoteId, 'rate_sheet_daily')}
                />
            </TableCell>
            {
                QuoteItemTaxData &&
                <>
                    {
                        QuoteTaxValue === null ?
                            <TableCell>
                                <NumericFormat
                                    value={`011110`}
                                    data-testid="taxid"
                                    id="tax"
                                    suffix=" %"
                                    onValueChange={(values) => HandleEditQuoteDescription(values.floatValue, columnId, editquoteId, 'tax')}
                                    customInput={TextField}
                                />
                            </TableCell>
                            : <TableCell className={isDayRentalItemSelectedData && classes.editUnitPriceBox}>
                                <NumericFormat
                                    value={QuoteTaxValue}
                                    data-testid="taxid"
                                    id="tax"
                                    suffix="%"
                                    onValueChange={(values) => HandleEditQuoteDescription(values.floatValue, columnId, editquoteId, 'tax')}
                                    customInput={TextField}
                                />
                            </TableCell>
                    }
                </>

            }

            <TableCell className={isDayRentalItemSelectedData && classes.editUnitPriceBox}>
                <NumericFormat
                    id="amount"
                    data-testid="amountid"
                    value={amount}
                    prefix="$ "
                    thousandSeparator=","
                    decimalSeparator="."
                    decimalScale={4}
                    onValueChange={(values) => HandleEditQuoteDescription(values.floatValue, columnId, editquoteId, 'amount')}
                    customInput={TextField}
                />
            </TableCell>
            {
                !QuoteAddNewSegmentState && !categoryBox &&
                <TableCell>
                    <img src={deletePng} alt="deletePng" onClick={() => deleteOpenTableItemModal(editquoteId)} data-testid={`deleteItemTestid-${editquoteId}`} />
                </TableCell>
            }

        </>
    )
}

export const MonthRentalTable = (props: MonthRentalTableProps) => {
    const { QuoteAddNewSegmentState,
        categoryBox,
        classes,
        selected,
        handleRowItemClick, cat_classValue,
        descriptionValue, HandleEditQuoteDescription, rate_sheet_catValue,
        rate_sheet_minValue, QuoteItemTaxData, QuoteTaxValue, amount,
        rate_sheet_weeklyValue, columnId, editquoteId, begin, deleteOpenTableItemModal, index } = props

    const isMonthDataSelected = (id: number) => selected.indexOf(id) !== -1;
    const isMonthItemSelectedData = isMonthDataSelected(Number(editquoteId));

    return (
        <>
            {
                QuoteAddNewSegmentState && categoryBox &&
                <TableCell padding="checkbox" className={classes.checkBox}>
                    <Checkbox
                        id="checkBox"
                        checked={isMonthItemSelectedData}
                        onClick={(event) => { handleRowItemClick(event, columnId, editquoteId) }}
                        data-testid={`dataItemClickId-${columnId}`}
                    />
                </TableCell>
            }
            <TableCell className={classes.idBox}>{begin + index + 1}</TableCell>
            <TableCell className={isMonthItemSelectedData ? classes.editQuantityInputBox : classes.editunitFieldContent}>
                <TextField
                    value={cat_classValue}
                    id="cat_class"
                    onChange={(e) => HandleEditQuoteDescription(e, columnId, editquoteId, 'cat_class')}
                    data-testid="ord_quantityid"
                    style={{ width: '100%', height: '62px' }}
                />
            </TableCell>
            <TableCell
                className={isMonthItemSelectedData ? classes.editInputBox : classes.editInputField}>
                <TextField data-testid="descriptionid" id="description" value={descriptionValue}
                    onChange={(e) => HandleEditQuoteDescription(e, columnId, editquoteId, 'description')}
                />
            </TableCell>
            <TableCell className={isMonthItemSelectedData ? classes.editQuantityInputBox : classes.editunitFieldContent}>
                <TextField data-testid="unitid" id="rate_sheet_cat" value={rate_sheet_catValue} onChange={(e) => HandleEditQuoteDescription(e, columnId, editquoteId, 'rate_sheet_cat')} />
            </TableCell>
                <TableCell className={isMonthItemSelectedData && classes.editUnitPriceBox}>
                <NumericFormat
                    thousandSeparator=","
                    decimalSeparator="."
                    decimalScale={4}
                    id="rate_sheet_min"
                    data-testid="unitid"
                    value={rate_sheet_minValue}
                    prefix="$ "
                    customInput={TextField}
                    onValueChange={(values) => HandleEditQuoteDescription(`$${values.floatValue}`, columnId, editquoteId, 'rate_sheet_min')}
                />
                </TableCell>
            <TableCell className={isMonthItemSelectedData && classes.editUnitPriceBox}>
                   <NumericFormat
                    thousandSeparator=","
                    decimalSeparator="."
                    decimalScale={4}
                    id="rate_sheet_weekly"
                    data-testid="priceid"
                    value={rate_sheet_weeklyValue}
                    prefix="$ "
                    customInput={TextField}
                    onValueChange={(values) => HandleEditQuoteDescription(`$${values.floatValue}`, columnId, editquoteId, 'rate_sheet_weekly')}
                />
            </TableCell>

            <TableCell className={isMonthItemSelectedData && classes.editUnitPriceBox}>
                <NumericFormat
                    thousandSeparator=","
                    decimalSeparator="."
                    decimalScale={4}
                    id="amount"
                    data-testid="amountid"
                    value={amount}
                    prefix="$ "
                    customInput={TextField}
                    onValueChange={(values) => HandleEditQuoteDescription(values.floatValue, columnId, editquoteId, 'amount')}
                />
            </TableCell>
            {
                !QuoteAddNewSegmentState && !categoryBox &&
                <TableCell>
                    <img
                        onClick={() => deleteOpenTableItemModal(editquoteId)}
                        alt="deletePng"
                        data-testid={`deleteItemTestid-${editquoteId}`}
                        src={deletePng}
                    />
                </TableCell>
            }


        </>
    )
}

export const RentalTableCellData = (props: RentalTableCellDataProps) => {
    const {
        QuoteAddNewSegmentState,
        categoryBox,
        classes,
        selected,
        selectedRadioValue,
        handleRowItemClick, cat_classValue,
        descriptionValue, HandleEditQuoteDescription, rate_sheet_catValue,
        rate_sheet_minValue, QuoteItemTaxData, QuoteTaxValue, amount, rate_sheet_weeklyValue,
        rate_sheet_monthlyValue,
        rate_sheet_dailyValue, columnId, editquoteId, begin, deleteOpenTableItemModal, index } = props

    const isRentalDataSelected = (id: number) => selected.indexOf(id) !== -1;
    const isRentalItemSelectedData = isRentalDataSelected(Number(editquoteId));

    return (
        <>
            {
                selectedRadioValue === configJSON.dayTitle &&
                <DayRentalTable
                    QuoteAddNewSegmentState={QuoteAddNewSegmentState}
                    categoryBox={categoryBox}
                    classes={classes}
                    selected={selected}
                    selectedRadioValue={selectedRadioValue}
                    handleRowItemClick={handleRowItemClick}
                    cat_classValue={cat_classValue}
                    descriptionValue={descriptionValue}
                    HandleEditQuoteDescription={HandleEditQuoteDescription}
                    rate_sheet_catValue={rate_sheet_catValue}
                    rate_sheet_minValue={rate_sheet_minValue}
                    QuoteItemTaxData={QuoteItemTaxData}
                    QuoteTaxValue={QuoteTaxValue}
                    amount={amount}
                    rate_sheet_dailyValue={rate_sheet_dailyValue}
                    columnId={columnId}
                    editquoteId={editquoteId}
                    begin={begin}
                    deleteOpenTableItemModal={deleteOpenTableItemModal}
                    index={index}
                />
            }

            {
                selectedRadioValue === configJSON.weekTitle &&

                <MonthRentalTable
                    QuoteAddNewSegmentState={QuoteAddNewSegmentState}
                    categoryBox={categoryBox}
                    classes={classes}
                    selected={selected}
                    selectedRadioValue={selectedRadioValue}
                    handleRowItemClick={handleRowItemClick}
                    cat_classValue={cat_classValue}
                    descriptionValue={descriptionValue}
                    HandleEditQuoteDescription={HandleEditQuoteDescription}
                    rate_sheet_catValue={rate_sheet_catValue}
                    rate_sheet_minValue={rate_sheet_minValue}
                    QuoteItemTaxData={QuoteItemTaxData}
                    QuoteTaxValue={QuoteTaxValue}
                    amount={amount}
                    rate_sheet_weeklyValue={rate_sheet_weeklyValue}
                    columnId={columnId}
                    editquoteId={editquoteId}
                    begin={begin}
                    deleteOpenTableItemModal={deleteOpenTableItemModal}
                    index={index}
                />
            }


            {
                selectedRadioValue === configJSON.monthTitle &&

                <>
                    {
                        QuoteAddNewSegmentState && categoryBox &&
                        <TableCell padding="checkbox" className={classes.checkBox}>
                            <Checkbox
                                id="checkBox"
                                checked={isRentalItemSelectedData}
                                onClick={(event) => { handleRowItemClick(event, columnId, editquoteId) }}
                                data-testid={`dataItemClickId-${columnId}`}
                            />
                        </TableCell>
                    }
                    <TableCell className={classes.idBox}>{begin + index + 1}</TableCell>
                    <TableCell className={isRentalItemSelectedData ? classes.editQuantityInputBox : classes.editunitFieldContent}>
                        <TextField
                            value={cat_classValue}
                            id="cat_class"
                            onChange={(e) => HandleEditQuoteDescription(e, columnId, editquoteId, 'cat_class')}
                            data-testid="ord_quantityid"
                            style={{ width: '100%', height: '62px' }}
                        />
                    </TableCell>
                    <TableCell
                        className={isRentalItemSelectedData ? classes.editInputBox : classes.editInputField}>
                        <TextField data-testid="descriptionid" id="description" value={descriptionValue}
                            onChange={(e) => HandleEditQuoteDescription(e, columnId, editquoteId, 'description')}
                        />
                    </TableCell>
                    <TableCell className={isRentalItemSelectedData ? classes.editQuantityInputBox : classes.editunitFieldContent}>
                        <TextField data-testid="unitid" id="rate_sheet_cat" value={rate_sheet_catValue} onChange={(e) => HandleEditQuoteDescription(e, columnId, editquoteId, 'rate_sheet_cat')} />
                    </TableCell>
                        <TableCell className={isRentalItemSelectedData && classes.editUnitPriceBox}>
                        <NumericFormat
                            thousandSeparator=","
                            decimalSeparator="."
                            decimalScale={4}
                            id="rate_sheet_min"
                            data-testid="unitid"
                            value={rate_sheet_minValue}
                            prefix="$ "
                            customInput={TextField}
                            onValueChange={(values) => HandleEditQuoteDescription(`$${values.floatValue}`, columnId, editquoteId, 'rate_sheet_min')}
                        />
                        </TableCell>
                    <TableCell className={isRentalItemSelectedData && classes.editUnitPriceBox}>
                    <NumericFormat
                            thousandSeparator=","
                            decimalSeparator="."
                            decimalScale={4}
                            id="rate_sheet_monthly"
                            data-testid="priceid"
                            value={rate_sheet_monthlyValue}
                            prefix="$ "
                            customInput={TextField}
                            onValueChange={(values) => HandleEditQuoteDescription(`$${values.floatValue}`, columnId, editquoteId, 'rate_sheet_monthly')}
                        />
                    </TableCell>
                    
                    <TableCell className={isRentalItemSelectedData && classes.editUnitPriceBox}>
                        <NumericFormat
                            value={amount}
                            id="amount"
                            data-testid="amountid"
                            customInput={TextField}
                            decimalScale={4}
                            prefix="$ "
                            thousandSeparator=","
                            decimalSeparator="."
                            onValueChange={(values) => HandleEditQuoteDescription(values.floatValue, columnId, editquoteId, 'amount')}

                        />
                    </TableCell>
                    {
                        !QuoteAddNewSegmentState && !categoryBox &&
                        <TableCell>
                            <img onClick={() => deleteOpenTableItemModal(editquoteId)} src={deletePng} alt="deletePng" data-testid={`deleteItemTestid-${editquoteId}`} />
                        </TableCell>
                    }

                </>
            }
        </>

    )
}

export const TotalAmountBox = (props: TotalAmountBoxProps) => {
    const { classes, calculateValue, s_and_h_chargesValue, other_charges, handleEditQuoteDetail, discount, tax_rate_amount, totalTax, totalValue } = props
    return (

        <Box className={classes.editquoteTotalAmountBox}>
            <Box>
                <Box className={classes.editSubTotalContentBox}>
                    <Typography>{configJSON.subTotalContent}</Typography>
                    <Typography style={{width:"300px"}}>$ {calculateValue.toFixed(2)}</Typography>
                </Box>
                <Box className={classes.editSubTotalContentBox}>
                    <Typography>{configJSON.chargesContent}</Typography>
                    {
                        s_and_h_chargesValue === null ?
                            <NumericFormat
                            style={{width:"300px"}}
                                id="s_and_h_charges"
                                value={0}
                                prefix="$ "
                                onValueChange={(values) => handleEditQuoteDetail('s_and_h_charges', values.floatValue)}
                                customInput={TextField}
                                data-testid="s_and_h_chargesid"
                            /> :
                            <NumericFormat
                            style={{width:"300px"}}
                                id="s_and_h_charges"
                                value={s_and_h_chargesValue}
                                prefix="$ "
                                onValueChange={(values) => handleEditQuoteDetail('s_and_h_charges', values.floatValue)}
                                customInput={TextField}
                                data-testid="s_and_h_chargesid"
                            />
                    }

                </Box>
                <Box className={classes.editSubTotalContentBox}>
                    <Typography>{configJSON.otherchargesContent}</Typography>
                    {
                        other_charges === null ?
                            <NumericFormat
                            style={{width:"300px"}}
                                id="other_charges"
                                value={0}
                                prefix="$ "
                                onValueChange={(values) => handleEditQuoteDetail('other_charges', values.floatValue)}
                                customInput={TextField}
                                data-testid="other_chargesid"
                            /> :
                            <NumericFormat
                            style={{width:"300px"}}
                                id="other_charges"
                                value={other_charges}
                                prefix="$ "
                                onValueChange={(values) => handleEditQuoteDetail('other_charges', values.floatValue)}
                                customInput={TextField}
                                data-testid="other_chargesid"
                            />
                    }
                </Box>

                <Box className={classes.editSubTotalContentBox}>
                    <Typography>{configJSON.discountContent}</Typography>
                    {
                        discount == null ?

                            <NumericFormat
                            style={{width:"300px"}}
                                id="discount"
                                value={0}
                                prefix="$ "
                                onValueChange={(values) => handleEditQuoteDetail('discount', values.floatValue)}
                                customInput={TextField}
                                data-testid="discountid"
                            /> :
                            <NumericFormat
                            style={{width:"300px"}}
                                id="discount"
                                value={discount}
                                prefix="$ "
                                onValueChange={(values) => handleEditQuoteDetail('discount', values.floatValue)}
                                customInput={TextField}
                                data-testid="discountid"
                            />

                    }

                </Box>
                <Box className={classes.edittaxContent}>
                    <Typography className={classes.quoteDetailTitle}>{configJSON.taxContent}</Typography>
                    <Box className={classes.edittaxInputBox}>
                        {
                            tax_rate_amount === null ?
                                <NumericFormat
                                    id="tax_rate_amount"
                                    value={0}
                                    suffix=" %"
                                    onValueChange={(values) => handleEditQuoteDetail('tax_rate_amount', values.floatValue)}
                                    customInput={TextField}
                                    data-testid="tax_rate_amountid"
                                /> :
                                <NumericFormat
                                    id="tax_rate_amount"
                                    value={tax_rate_amount}
                                    suffix=" %"
                                    onValueChange={(values) => handleEditQuoteDetail('tax_rate_amount', values.floatValue)}
                                    customInput={TextField}
                                    data-testid="tax_rate_amountid"
                                />
                        }

                        <NumericFormat
                        style={{width:"300px"}}
                            id="tax"
                            value={totalTax}
                            prefix="$ "
                            onValueChange={(values) => handleEditQuoteDetail('tax', values.floatValue)}
                            customInput={TextField}
                            data-testid="taxInputid"
                        />

                    </Box>
                </Box>
                <Box className={classes.editSubTotalContentBox}>
                    <Typography className={classes.edittotalPrice}>{configJSON.totalContent}</Typography>
                    <Typography style={{width:"300px"}}>$ {totalValue}</Typography>
                </Box>
            </Box>
        </Box>
    )
}

export const QuoteTableHead = (props: {  QuoteAddNewSegmentState: boolean,
    categoryBox: boolean,
    handleSelectAllItemClick: (event: React.ChangeEvent<HTMLInputElement>, columnId: number) => void,
    selectedData: string[] | number[],
    classes: any,
    columnId: number,
    checkedBox: boolean,
    QuoteItemTaxData: boolean | undefined,
    columnlength: number,
    radioChecklabel:string
}) => {
    const { QuoteAddNewSegmentState, categoryBox, classes, QuoteItemTaxData, handleSelectAllItemClick, selectedData, columnId, checkedBox, columnlength,radioChecklabel } = props
    const numSelectedData = selectedData.length
    return (
        <>
            {
                columnlength > 0 &&

                <>
                    {
                        checkedBox === true ?

                            <TableHead>
                                {QuoteAddNewSegmentState && categoryBox && <TableCell padding="checkbox" className={classes.editCheckboxInput}>
                                    <Checkbox
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleSelectAllItemClick(event, columnId) }}
                                        data-testid={"selectAllItemTestid"}
                                        checked={selectedData.length > 0 && numSelectedData === selectedData.length}
                                        indeterminate={numSelectedData > 0 && numSelectedData < selectedData.length}
                                    />
                                </TableCell>}
                                <TableCell style={{width:'5%'}}>Sr. No</TableCell>
                                <TableCell className={classes.editUnitPricewidthBox}>Cat-class</TableCell>
                                <TableCell style={{width:'40%'}}>Description</TableCell>
                                <TableCell className={classes.editUnitPricewidthBox}>Category</TableCell>
                                <TableCell className={classes.editUnitPricewidthBox}>Min.</TableCell>
                                {radioChecklabel === 'A day' && <TableCell className={classes.editUnitPricewidthBox}>Daily</TableCell>}
                                {radioChecklabel === 'A week' && <TableCell className={classes.editUnitPricewidthBox}>Weekly</TableCell>}
                                {radioChecklabel === 'A month' && <TableCell className={classes.editUnitPricewidthBox}>Monthly</TableCell>}
                                <TableCell>Ext. Rates</TableCell>
                            </TableHead> :

                            <TableHead>
                                {QuoteAddNewSegmentState && categoryBox && <TableCell className={classes.editCheckboxInput} padding="checkbox">
                                    <Checkbox
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => { handleSelectAllItemClick(event, columnId) }}
                                        data-testid={"selectAllItemTestid"}
                                        checked={selectedData.length > 0 && numSelectedData === selectedData.length}
                                        indeterminate={numSelectedData > 0 && numSelectedData < selectedData.length}
                                    />
                                </TableCell>}
                                <TableCell className={classes.editSrNoInput}>{'Sr No.'}</TableCell>
                                <TableCell className={classes.editInputField}>{'Description'}</TableCell>
                                <TableCell className={classes.editunitFieldContent}>{'Order Qty.'}</TableCell>
                                <TableCell className={classes.editunitFieldContent}>{'Unit'}</TableCell>
                                <TableCell className={QuoteItemTaxData === true ? classes.editPricetaxFieldContent : classes.editPriceFieldContent }>{'Unit Price'}</TableCell>
                                {
                                    QuoteItemTaxData === true &&
                                        <TableCell className={classes.editPriceFieldContent}>{'Tax (%)'}</TableCell> 
                                }

                                <TableCell>{'Ext Price'}</TableCell>
                            </TableHead>
                    }
                </>
            }
        </>
    )
}

export const QuoteTableCellData = (props: QuoteTableCellDataProps) => {
    const { QuoteAddNewSegmentState,
        categoryBox,
        classes,
        selected,
        selectedRadioValue,
        handleRowItemClick, cat_classValue,
        descriptionValue, HandleEditQuoteDescription, rate_sheet_catValue,
        rate_sheet_minValue, QuoteItemTaxData, QuoteTaxValue, amount, rate_sheet_weeklyValue,
        rate_sheet_monthlyValue,
        rate_sheet_dailyValue, columnId, editquoteId, index, checkedBox,
        unitValue,
        priceValue,
        ord_quantityValue, begin, deleteOpenTableItemModal
    } = props
    return (
        <>
            {
                checkedBox === true ?
                    <RentalTableCellData
                        classes={classes}
                        QuoteAddNewSegmentState={QuoteAddNewSegmentState}
                        categoryBox={categoryBox}
                        handleRowItemClick={handleRowItemClick}
                        HandleEditQuoteDescription={HandleEditQuoteDescription}
                        descriptionValue={descriptionValue}
                        cat_classValue={cat_classValue}
                        rate_sheet_catValue={rate_sheet_catValue}
                        rate_sheet_minValue={rate_sheet_minValue}
                        QuoteItemTaxData={QuoteItemTaxData}
                        rate_sheet_dailyValue={rate_sheet_dailyValue}
                        rate_sheet_weeklyValue={rate_sheet_weeklyValue}
                        rate_sheet_monthlyValue={rate_sheet_monthlyValue}
                        columnId={columnId}
                        editquoteId={editquoteId}
                        amount={amount}
                        selected={selected}
                        begin={begin}
                        index={index}
                        deleteOpenTableItemModal={deleteOpenTableItemModal}
                        data-testid={`TableCellRentalData-${editquoteId}`}
                        selectedRadioValue={selectedRadioValue}
                        QuoteTaxValue={QuoteTaxValue}
                    /> :

                    <TableCellData
                        data-testid={`TableCellDataId-${columnId}`}
                        classes={classes}
                        QuoteAddNewSegmentState={QuoteAddNewSegmentState}
                        categoryBox={categoryBox}
                        amount={amount}
                        selected={selected}
                        begin={begin}
                        index={index}
                        columnId={columnId}
                        editquoteId={editquoteId}
                        deleteOpenTableItemModal={deleteOpenTableItemModal}
                        ord_quantityValue={ord_quantityValue}
                        handleRowItemClick={handleRowItemClick}
                        HandleEditQuoteDescription={HandleEditQuoteDescription}
                        descriptionValue={descriptionValue}
                        unitValue={unitValue}
                        priceValue={priceValue}
                        QuoteItemTaxData={QuoteItemTaxData}
                        QuoteTaxValue={QuoteTaxValue}
                    />
            }

        </>
    )
}

// Customizable Area End
class EditQuoteScanResult extends EditQuoteScanResultController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes, navigation, handleSaveData } = this.props
        const { editQuoteDetail } = this.state
        const selectedDataValue = this.state.tablesData.some((item => item.selected.length > 0))
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Header navigation={navigation} handleSaveData={handleSaveData} history={this.props.history} />

                <Box style={{ padding: "30px 0" }}>
                    <Box className={classes.breadcrumbStyle}>
                        <Breadcrumbs
                            separator={">"}
                            aria-label="breadcrumb"

                        >
                            <Link color="inherit" style={{ textDecoration: "underline" }} href="/DashboardWeb">
                                Dashboard
                            </Link>
                            <Link color="inherit" style={{ textDecoration: "underline" }} onClick={this.cancelEditHandler} >
                                Create a new report
                            </Link>

                            <Typography style={{ color: "orange" }}>Edit Quote</Typography>
                        </Breadcrumbs>
                    </Box>
                </Box>

                <Box className={classes.editquoteScanBox}>

                    <Box className={classes.itemWiseTaxBox}>
                        <Typography variant="h5" className={classes.editquoteTitle}>{configJSON.editQuote}</Typography>
                       {!this.props.location.state.checkedBox &&  <Box className={classes.editTaxContentBox}>
                            <Switch
                                onChange={this.handleTaxfieldChange}
                                name="checkedA"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                data-testid="handleTaxfieldChange"
                                checked={this.state.tablesData[0]?.data?.every((item: any) => item.taxSwitch)}
                            />
                            <Typography>{configJSON.itemWiseTax}</Typography>
                        </Box>}
                    </Box>
                    <Box className={classes.editquoteDeatilBox}>
                        <Grid container spacing={2}>
                            <>
                                <Grid item sm={4}>
                                    <Box>
                                        <Typography variant="h6" className={classes.editquotedetailsContent}>{configJSON.quotedetailsContent}</Typography>
                                        <Typography>{configJSON.quoteNumberContent}</Typography>
                                        <TextField value={editQuoteDetail.quote_id} id="quote_id" onChange={(e) => this.handleEditQuoteDetail('quote_id', e.target.value)} />
                                        <Typography>{configJSON.quoteDateContent}</Typography>

                                        <QuoteCreateDateBox data-testid="QuoteCreateDateId" handleDate={this.handleDate} handleEditQuoteDetail={this.handleEditQuoteDetail} editQuoteDetail={this.state.editQuoteDetail} classes={classes} typeOfFocused={typeof this.state.typeOfFocused === 'string' ? this.state.typeOfFocused : null} />


                                        <Typography>{configJSON.expiryquoteTitle}</Typography>

                                        <QuoteExpiryDateitemBox handleDate={this.handleDate} handleEditQuoteDetail={this.handleEditQuoteDetail} editQuoteDetail={this.state.editQuoteDetail} classes={classes} typeOfFocused={typeof this.state.typeOfFocused === 'string' ? this.state.typeOfFocused : null} />

                                    </Box>
                                </Grid>
                                <Grid item sm={4}>
                                    <Box>
                                        <Typography variant="h6" className={classes.editquotedetailsContent}>{configJSON.supplierdetailsContent}</Typography>
                                        <Typography>{configJSON.supplierNameContent}</Typography>
                                        <TextField value={editQuoteDetail.project} id="project" onChange={(e) => this.handleEditQuoteDetail('project', e.target.value)} />
                                        <Typography>{configJSON.supplierAddressContent}</Typography>
                                        <TextField value={editQuoteDetail.supplier_address} id="supplier_address" onChange={(e) => this.handleEditQuoteDetail('supplier_address', e.target.value)} />
                                        <Typography>{configJSON.salesPersonTitle}</Typography>
                                        <TextField value={editQuoteDetail.supplier_salesperson} id="supplier_salesperson" onChange={(e) => this.handleEditQuoteDetail('supplier_salesperson', e.target.value)} />
                                        <Typography>{configJSON.supplierContactContent}</Typography>
                                        <TextField value={editQuoteDetail.supplier_contact} id="supplier_contact" onChange={(e) => this.handleEditQuoteDetail('supplier_contact', e.target.value)} />
                                    </Box>
                                </Grid>
                                <Grid item sm={4}>
                                    <Box>
                                        <Typography variant="h6" className={classes.editquotedetailsContent}>{configJSON.projectdetailsContent}</Typography>
                                        <Typography>{configJSON.siteNameContent}</Typography>
                                        <TextField value={editQuoteDetail.site_name} id="site_name" onChange={(e) => this.handleEditQuoteDetail('site_name', e.target.value)} />
                                        <Typography>{configJSON.siteAddressContent}</Typography>
                                        <TextField value={editQuoteDetail.site_address} id="site_address" onChange={(e) => this.handleEditQuoteDetail('site_address', e.target.value)} />
                                        <Typography>{configJSON.contactPersonTitle}</Typography>
                                        <TextField value={editQuoteDetail.site_contactperson} id="site_contactperson" onChange={(e) => this.handleEditQuoteDetail('site_contactperson', e.target.value)} />
                                        <Typography>{configJSON.siteContactContent}</Typography>
                                        <QuoteSiteDataBox editQuoteDetail={this.state.editQuoteDetail} handleEditQuoteDetail={this.handleEditQuoteDetail} />
                                    </Box>
                                </Grid>
                            </>
                        </Grid>
                    </Box>

                    <Box>
                        <Box className={classes.addItemSegmentBox}>
                            {
                                this.props.location.state.checkedBox ?
                                    <Box className={classes.RentalAddItemSegmentBox}>
                                        <Typography variant="h6" className={classes.RentaleditquotedetailsContent}>{configJSON.itemdetailsContent}</Typography>
                                        <FormControl className={classes.ItemFormControl} >
                                            <FormLabel className={classes.ItemFormLabel} >{configJSON.showRateTitle}</FormLabel>
                                            <RadioGroup className={classes.ItemFormControlGroup} defaultValue={configJSON.dayTitle} value={this.state.selectedRadioValue} data-testid="radioTestId" onChange={this.handleRadioChange}>
                                                <FormControlLabel value={configJSON.dayTitle} control={<Radio />} label={configJSON.dayTitle} />
                                                <FormControlLabel value={configJSON.weekTitle} control={<Radio />} label={configJSON.weekTitle} />
                                                <FormControlLabel value={configJSON.monthTitle} control={<Radio />} label={configJSON.monthTitle} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Box> :
                                    <Typography variant="h6" className={classes.editquotedetailsContent}>{configJSON.itemdetailsContent}</Typography>
                            }

                            <AddSegmentItemButton selected={selectedDataValue} classes={classes} addNewSegmentHandler={this.addNewSegmentHandler} deleteOpenModal={this.deleteOpenModal} deleteCloseModal={this.deleteCloseModal} />
                        </Box>

                        <DragDropContext
                            onDragEnd={this.onDragEnd}
                            data-testid="DragDropContextTestid">
                            {this.state.tablesData.map((column, columnId) => {
                                const { page, rowsPerPage } = column.pagination;
                                const begin = (page - 1) * rowsPerPage;
                                const end = Math.min(begin + rowsPerPage, column.data.length);

                                const taxSwitchValue = column.data.map((taxvalue) => taxvalue?.taxSwitch)

                                return (
                                    <Box key={columnId}
                                        className={
                                            column.data.length == 0 ?
                                                classes.edititemtableRedBox : classes.edititemtableBox
                                        }
                                    >
                                        <DeleteItemButton
                                            classes={classes}
                                            categoryErrorMessageVal={column.categoryErrorMessage}
                                            addNewSegmentVal={column.QuoteAddNewSegmentState}
                                            addCategoryVal={column.category_name}
                                            handleCategoryChangeName={this.handleCategoryChange}
                                            columnIdVal={column.id}
                                            data-testid={`DeleteButtonId-${column.id}`}
                                            deleteCategoryModal={this.deleteCategoryModal}
                                        />
                                        <Table>
                                            <Droppable
                                                droppableId={columnId.toString()}
                                                key={columnId}
                                                data-testid={`droppableTestId-${columnId}`}
                                            >
                                                {(provided, snapshot) => {
                                                    return (
                                                        <div className={classes.tableHeadBox}>
                                                            <QuoteTableHead
                                                                QuoteAddNewSegmentState={column.QuoteAddNewSegmentState}
                                                                categoryBox={column.categoryBox}
                                                                handleSelectAllItemClick={this.handleSelectAllItemClick}
                                                                selectedData={column.selected}
                                                                classes={classes}
                                                                columnId={column.id}
                                                                data-testid={`quotetableheadId-${columnId}`}
                                                                columnlength={column.data.length}
                                                                QuoteItemTaxData={taxSwitchValue[0]}
                                                                checkedBox={this.props.location.state.checkedBox}
                                                                radioChecklabel={this.state.selectedRadioValue}
                                                            />


                                                            <TableBody
                                                                {...provided.droppableProps}
                                                                ref={provided.innerRef}
                                                                className={classes.edittableBodyBox}
                                                                data-testid={`tableBodyTestId`}
                                                            >
                                                                {
                                                                    (column.data.length == 0 && column.id !== 1) ?
                                                                        <NoItemDataBox classes={classes} data={""} />
                                                                        :
                                                                        <div style={{ display: 'table',width:'100%' }}>
                                                                            {column?.data.length !== 0 ? column.data.slice(begin, end).map((editquoteData: any, index: number) => {
                                                                                return (
                                                                                    <Draggable
                                                                                        index={index}
                                                                                        key={editquoteData.id.toString()}
                                                                                        draggableId={editquoteData.id.toString()}
                                                                                        data-testid={`draggableTestId-${editquoteData.id}`}
                                                                                    >
                                                                                        {(provided, snapshot) => {
                                                                                            return (
                                                                                                <TableRow
                                                                                                    ref={provided.innerRef}
                                                                                                    {...provided.draggableProps}
                                                                                                    {...provided.dragHandleProps}
                                                                                                    hover
                                                                                                    key={editquoteData.id.toString()}
                                                                                                    data-testid="tablerowid"

                                                                                                >
                                                                                                    <QuoteTableCellData
                                                                                                        classes={classes}
                                                                                                        QuoteAddNewSegmentState={column.QuoteAddNewSegmentState}
                                                                                                        categoryBox={column.categoryBox}
                                                                                                        handleRowItemClick={this.handleRowItemClick}
                                                                                                        HandleEditQuoteDescription={this.HandleEditQuoteDescription}
                                                                                                        descriptionValue={editquoteData.description}
                                                                                                        cat_classValue={editquoteData.cat_class}
                                                                                                        rate_sheet_catValue={editquoteData.rate_sheet_cat}
                                                                                                        rate_sheet_minValue={editquoteData.rate_sheet_min}
                                                                                                        QuoteItemTaxData={editquoteData.taxSwitch}
                                                                                                        rate_sheet_dailyValue={editquoteData.rate_sheet_daily}
                                                                                                        rate_sheet_weeklyValue={editquoteData.rate_sheet_weekly}
                                                                                                        rate_sheet_monthlyValue={editquoteData.rate_sheet_monthly}
                                                                                                        columnId={column.id}
                                                                                                        editquoteId={editquoteData.id}
                                                                                                        amount={editquoteData.amount}
                                                                                                        selected={column.selected}
                                                                                                        begin={begin}
                                                                                                        index={index}
                                                                                                        deleteOpenTableItemModal={this.deleteOpenTableItemModal}
                                                                                                        data-testid={`TableCellData-${column.id}`}
                                                                                                        selectedRadioValue={this.state.selectedRadioValue}
                                                                                                        QuoteTaxValue={editquoteData.tax}
                                                                                                        ord_quantityValue={editquoteData.ord_quantity}
                                                                                                        unitValue={editquoteData.unit}
                                                                                                        priceValue={editquoteData.price}
                                                                                                        checkedBox={this.props.location.state.checkedBox}
                                                                                                    />

                                                                                                </TableRow>
                                                                                            );
                                                                                        }}
                                                                                    </Draggable>
                                                                                );

                                                                            }) : <NoItemTableRow classes={classes} QuoteItemTaxData={this.state.QuoteItemTaxData} />}

                                                                        </div>
                                                                }
                                                                {provided.placeholder}
                                                            </TableBody>
                                                        </div>
                                                    );
                                                }}

                                            </Droppable>
                                        </Table>
                                        < Pagination
                                            count={Math.ceil(column.data.length / column.pagination.rowsPerPage)}
                                            variant="outlined"
                                            shape="rounded"
                                            page={column.pagination.page}
                                            data-testid={`editpaginationTestId-${columnId}`}
                                            onChange={(event, page) => this.handlePageChange(event, page, columnId)}
                                        />
                                        <AddSegmentDataBox
                                            data-testid={`AddSegmentBoxId-${columnId}`}
                                            addNewSegmentVal={column.QuoteAddNewSegmentState}
                                            subTotalDataValue={column.subtotalValue}
                                            columnIdVal={column.id}
                                            classes={classes}
                                            addNewItemRowData={this.addNewItemRow}
                                        />
                                    </Box>
                                )
                            })}
                        </DragDropContext>
                    </Box>

                    <TotalAmountBox
                        classes={classes}
                        calculateValue={this.state.calculateValue}
                        s_and_h_chargesValue={editQuoteDetail.s_and_h_charges}
                        other_charges={editQuoteDetail.other_charges}
                        handleEditQuoteDetail={this.handleEditQuoteDetail}
                        discount={editQuoteDetail.discount}
                        tax_rate_amount={editQuoteDetail.tax_rate_amount}
                        totalTax={this.totalTax()}
                        totalValue={this.state.totalValue}
                    />

                </Box >

                <Box className={classes.cancelSaveButton}>
                    <Button onClick={this.cancelEditHandler} data-testid="cancelTestid">
                        {configJSON.invoiceCancel}
                    </Button>
                    <Button onClick={this.editQuoteDetailApi} data-testid="invoiceSaveTestid">
                        {configJSON.invoiceSave}
                    </Button>
                </Box>

                <Modal
                    open={this.state.deleteModalState}
                    onClose={this.deleteCloseModal}
                >
                    <DeleteModal
                        openModal={this.state.deleteModalState}
                        handleClose={this.deleteCloseModal}
                        DeleteItemsHandler={this.DeleteItemsHandler}
                        deleteHandler={this.deleteHandler}
                        deleteCategory={this.deleteCategory}
                        selectedItem={this.state.selected}
                        classes={classes}
                        data-testid="deleteModalId"
                    />
                </Modal>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const StartDateCalendarEdit = styled(KeyboardDatePicker)({
    margin: 0,
    borderRadius:'4px',
    display:'flex',
    justifyContent:"center",
    "& .MuiFormHelperText-root.Mui-error": {
        display: 'none !important'
    },
    "& .MuiInputBase-input": {
        border:'none',
        paddingLeft:'11px'
    },
   
});

export const editQuoteScanResultStyle = () => ({
    editquoteScanBox: {
        background: '#fff',
        borderRadius: '10px',
        padding: '50px 25px 50px 50px',
        '& p': {
            color: '#0a0019',
            fontWeight: 500,
            margin: '15px 0 5px',
        },
        '& .MuiInput-underline::before': {
            display: 'none'
        },
        '& .MuiInput-underline::after': {
            display: 'none'
        },
        '& .MuiTable-root': {
            borderCollapse: 'separate',
            borderSpacing: '8px'
        },
        '& .MuiTableContainer-root': {
            overflowX: 'unset',
        },
        '& .MuiTableCell-root': {
            borderBottom: 'none',
            padding: 0
        },
        '& .MuiTableCell-head': {
            paddingLeft: 0,
            fontWeight: 600,
        },
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: '#000'
        }
    },
    editquoteTitle: {
        color: '#000',
        fontWeight: 600
    },
    editquoteDeatilBox: {
        paddingBottom: '20px',
        '& .MuiFormControl-root': {
            width: '95%',
            marginBottom: '15px',
            marginRight: '25px',
        },
        '& input': {
            color: '#000',
            fontWeight: 500,
            padding: '20px',
            border: '2px solid #d5d5d5',
            background: '#fff',
            borderRadius: '5px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap' as 'nowrap',
            display: 'block',
            width: '95%',
            fontSize: '16px'
        },
    },
    editquotedetailsContent: {
        color: '#000',
        fontWeight: 600,
        fontSize: '18px',
        marginTop: '50px',
        marginBottom: '20px'
    },

    RentaleditquotedetailsContent: {
        color: '#000',
        fontWeight: 600,
        fontSize: '18px',
    },

    edittableBodyBox: {
        position: 'relative' as 'relative',
        display: 'blocks',
        zIndex: 1,
        "& td": {
            borderRadius: '5px',
            color: '#aeaab2',
            fontWeight: 500,
            width: 'min-content',
            '& div': {
                width: '100%'
            },
            '&:first-child': {
                textAlign: 'center',
                width: '75px',
                padding: 0
            },
            '&:last-child': {
                border: 'none !important',
                textAlign: 'center',
            },
            '& input:focus-visible': {
                border: '2px solid #d5d5d5',
                outline: 'none',
            }
        },
        '& td:focus-visible': {
            border: 'none',
            outline: 'none',
        },
        '& img': {
            width: '20px',
            height: '23px',
            color: '#d5d5d5'
        }
    },

    idBox: {
        border: '2px solid #d5d5d5 !important',
        width: '5% !important',
        textAlign: 'center' as 'center',
        borderRadius: '5px'
    },

    checkBox: {
        border: 'none !important',
        width: '5% !important',
    },

    editsubTotalBox: {
        display: 'flex',
        alignItems: 'center',
        '& p:first-child': {
            color: '#000',
            margin: '0px'
        },
        '& p:last-child': {
            marginLeft: '20px',
            color: '#aeaab2',
            fontWeight: 500,
            padding: '12px 100px 12px 12px',
            border: '2px solid #d5d5d5',
            borderRadius: '5px',
            marginBottom: '0px',
            marginTop: '0px',
            width:'300px'
        }
    },
    editquoteTotalAmountBox: {
        border: '2px solid #eee',
        borderRadius: '8px',
        padding: '20px',
        width: 'fit-content',
        margin: '0 20px 0 auto',

        '& input': {
            color: '#000',
            fontWeight: 500,
            padding: '12px',
            border: '2px solid #d5d5d5',
            background: '#fff',
            borderRadius: '5px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap' as 'nowrap',
            display: 'block',
        },
    },
    editSubTotalContentBox: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '15px',
        alignItems: 'center',
        '& p:last-child': {
            width: '40%',
            color: '#aeaab2',
            fontWeight: 500,
            padding: '12px',
            border: '2px solid #d5d5d5',
            borderRadius: '5px',
        },
    },
    edittaxContent: {
        display: 'flex',
        marginBottom: '20px',
    },
    edittaxInputBox: {
        textAlign: 'right' as 'right',
        width:'100%',
        '& .MuiFormControl-root:first-child': {
            width: '20%',
            marginRight: '20px',
        },
    },

    edittotalPrice: {
        fontWeight: 600,
        color: '#0a0019',
        margin: '15px 0 5px',
    },

    edititemtableBox: {
        marginRight: '29px',
        '& thead': {
            position: 'relative',
            top: '-40px',
            display: 'table'

        },
        '& table': {
            position: 'relative',
            '& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover': {
                background: '#fff'
            },
            '& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected': {
                background: '#fff'
            },
            '& .MuiTableRow-root.MuiTableRow-hover:hover': {
                background: '#fff'
            }
        },
        '& table::after': {
            content: '""',
            border: '2px solid #e8e8e8',
            borderRadius: '10px',
            position: 'absolute',
            top: '23px',
            left: '-7px',
            width: '100%',
            height: '100%',
            zIndex: 'unset'
        },
        '& .MuiPagination-root': {
            marginTop: '5%',
            marginBottom: '30px',
            background: '#fff',
            boxShadow: '0 0 7px #e3d3d3',
            padding: '15px 20px',
            display: 'inline-block',
            marginLeft: '-3px'
        },
        '& input': {
            color: '#000',
            fontWeight: 500,
            padding: '20px',
            border: '2px solid #d5d5d5',
            background: '#fff',
            borderRadius: '5px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap' as 'nowrap',
            display: 'block',
            fontSize: '16px',
        },
    },

    edititemtableRedBox: {
        marginRight: '29px',

        '& table': {
            position: 'relative',
            '& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected': {
                background: '#fff'
            },
            '& .MuiTableRow-root.MuiTableRow-hover:hover': {
                background: '#fff'
            },
            '& .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover': {
                background: '#fff'
            }
        },
        '& .MuiPagination-root': {
            boxShadow: '0 0 7px #e3d3d3',
            padding: '15px 20px',
            display: 'inline-block',
            marginTop: '5%',
            marginBottom: '30px',
            marginLeft: '-3px',
            background: '#fff'
        },
        '& thead': {
            position: 'relative',
            top: '-40px',
            display: 'table'
        },
        '& input': {
            fontSize: '16px',
            color: '#000',
            fontWeight: 500,
            padding: '20px',
            border: '2px solid #d5d5d5',
            background: '#fff',
            borderRadius: '5px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap' as 'nowrap',
            display: 'block'
        },
        '& table::after': {
            borderRadius: '10px',
            // zIndex: 9
            zIndex: 'unset',
            position: 'absolute',
            top: '23px',
            left: '-7px',
            width: '101%',
            height: '97%',
            content: '""',
            border: '2px solid transparent',
        }
    },

    deleteButton: {
        border: '2px solid red !important',
        color: 'red !important',
        fontWeight: 600,
        marginRight: '20px',
        '&:hover': {
            background: '#fff'
        }
    },

    addItemSegmentBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '50px 0 60px 0',

        '& button': {
            border: '2px solid #98949f',
            borderRadius: '5px',
            padding: '10px',
            color: '#454051',
            textTransform: 'capitalize',
            fontWeight: 600,
            '&:hover': {
                background: '#fff'
            }
        },
        '& svg': {
            color: '#000',
            marginRight: '10px',
        },

    },
    cancelSaveButton: {
        display: 'flex',
        justifyContent: 'space-between',
        '& button': {
            background: '#fff',
            color: '#000',
            padding: '14px 80px',
            fontSize: '16px',
            textTransform: 'inherit' as 'inherit',
            marginTop: '30px',
            border: '2px solid #98949f',
            "&:hover": {
                background: '#05052b',
                color: '#fff',
                border: 'none'
            },
        },
    },
    editunitFieldContent: {
        textTransform: 'capitalize' as 'capitalize',
        width: '10% !important'
    },

    addNewSegmentBox: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            color: '#454051',
            textTransform: 'capitalize',
            fontWeight: 600,
            marginRight: '40px',
            marginTop: 0,
            marginBottom: 0,
        },
        '& input': {
            padding: '15px'
        },
        '& img': {
            width: '20px',
            height: '23px',
            marginLeft: '23px',
            color: '#d5d5d5'
        }
    },

    quoteDragBox: {
        border: '2px solid #dabfa4',
        marginTop: '30px',
        padding: '20px 10px',
        borderRadius: '10px',
        boxShadow: '0 0 2px #dabfa4',
        textAlign: 'center' as 'center',
        '& p:first-child': {
            color: '#4e4e51'
        },
        '& p:last-child': {
            color: '#000',
            fontWeight: 500
        }
    },

    editInputField: {
        width: '40% !important',
        '& div': {
            width: '100%'
        },
    },
    editInputBox: {
        width: '40% !important',
        '& div': {
            width: '100%'
        },
        '& input': {
            border: '2px solid #000',
        }
    },
    editQuantityInput: {
        width: '10% !important',
    },
    editQuantityInputBox: {
        width: '10% !important',
        '& input': {
            border: '2px solid #000',
            textTransform: 'capitalize' as 'capitalize',
        }
    },
    editUnitPriceBox: {
        '& input': {
            border: '2px solid #000',
            // width: '14%'
        }
    },
    errorBox: {
        color: 'red !important',
        fontSize: '14px',
        margin: '0px !important'
    },
    categoryErrorBox: {
        marginBottom: '60px'
    },
    itemWiseTaxBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    editTaxContentBox: {
        display: 'flex',
        alignItems: 'center',
        '& p': {
            margin: 0
        },
        '& .MuiSwitch-colorSecondary.Mui-checked': {
            color: '#fff',
        },
        '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
            background: '#000',
            opacity: 1
        }

    },
    editPriceFieldContent: {
        width: '10%'
    },
    editPricetaxFieldContent: {
        width: '10%'
    },
    editSrNoInput: {
        width: '5% !important'
    },
    editCheckboxInput: {
        width: '5% !important',
        textAlign: 'center' as 'center'
    },
    tableHeadBox: {
        display: 'grid',
    },
    editUnitPricewidthBox: {
        width: '10% !important'
    },
    ItemFormLabel: {
        color: '#000',
        fontWeight: 500,
        fontSize: '16px',
        display: "inline-block",
        marginRight: '30px',
        marginLeft: '30px',
    },
    ItemFormControl: {
        display: "inline-block"
    },
    ItemFormControlGroup: {
        display: "inline-block",
        "& .MuiRadio-colorSecondary.Mui-checked": {
            color: "#000",
        },
        "& input": {
            color: "#000",
        },
    },
    ItemDetailsText: {
        color: '#000',
        fontWeight: 600,
        fontSize: '18px',
        display: "inline-block",
        marginTop: '8px',
        marginRight: '30px',
    },
    RentalAddItemSegmentBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
})

export { EditQuoteScanResult }
export default withStyles(editQuoteScanResultStyle)(EditQuoteScanResult)
// Customizable Area End
